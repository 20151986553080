// import React from "react";
import QuestionDropdown from "../components/QuestionDropdown";
import Container from "../components/utility/Container";
// import FAQ from "../utils/faq.png";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
// import { textAnimate } from "./Home2";
const faq = [
  {
    question: "What is Amazing Properties?",
    answer:
      "Amazing Properties is a company that buys real estate properties nationwide specifically in the Midewest.",
  },
  {
    question: "Why does Amazing Properties focus on Midwest (Ohio, Lowa, and Wisconsin) properties?",
    answer:
      "Our dedicated focus is in Ohio, Iowa, Washington, and Wisconsin. It allows us to better understand the local real estate market and provide fair and competitive offers for properties in these states.",
  },
  {
    question: "What types of properties does Amazing Properties buy?",
    answer:
      "We buy various types of properties, including residential homes, commercial buildings, land, and more. If you have a property in Ohio that you want to sell, we're interested!",
  },

  {
    question: "How does the selling process work on Amazing Properties?",
    answer:
      "Simply submit your property details through our online form. Our team will assess the information and provide you with a fair cash offer. If accepted, we proceed with a quick and smooth transaction.",
  }

  ,

  {
    question: "Is there any cost or obligation to get an offer from Amazing Properties?",
    answer:
      "No, obtaining an offer from Amazing Properties is free, and there is no obligation. We believe in transparency and empowering property owners to make informed decisions.",
  }
  ,

  {
    question: "How fast can I expect to receive an offer from Amazing Properties?",
    answer:
      "Our team works diligently to assess your property details and typically provides a cash offer within a few business days. We understand the importance of a quick and efficient process.",
  }
  ,

  {
    question: "What if my property needs repairs or is in poor condition?",
    answer:
      "No problem! Amazing Properties purchases properties in any condition. You don't need to worry about repairs or renovations; we buy properties as-is.",
  }
  ,
  {
    question: "Can I trust the confidentiality of my property information with Amazing Properties?",
    answer:
      "Absolutely! We prioritize the confidentiality of your information. Your details are securely handled and used solely to assess your property for a potential offer.",
  }
  ,

  // {
  //   question: "How do I get started with selling my Ohio property to Amazing Properties?",
  //   answer:
  //     "Simply visit our website, fill out the property information form, and our team will reach out to you promptly. Start the process today and experience the convenience of selling your property with Amazing Properties.",
  // },
  // {
  //   question: "Are there any hidden fees or commissions when selling to Amazing Properties?",
  //   answer:
  //     "No, there are no hidden fees or commissions. The offer we provide is the amount you'll receive. We aim to make the selling process transparent and straightforward.",
  // }
  // ,


];



const FAQSection = () => {
  return (

    <Grid container spacing={2}>
      <Grid item xs={8} style={{ margin: 'auto' }}>
        <motion.div
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ staggerChildren: 0.5 }}
          className="py-28 bg-[#fff]"
        >
          {/* Start of FAQ */}

          <Container>
            <div className="flex flex-col lg:flex-row items-center justify-center" >

              <div className="flex flex flex-col items-center justify-center  w-[100%]" >
                {faq.map((i, index) => (
                  <QuestionDropdown key={index} {...i} />
                ))}
              </div>
            </div>
          </Container>


          {/* end of FAQ */}
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default FAQSection;
