import { useCallback, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormHelperText from "@mui/material/FormHelperText";
// import Checkbox from "@mui/material/Checkbox";
import { AppContext } from "../Context";

export default function SecondStep() {
  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant,
    margin
  } = useContext(AppContext);
  const { whendo, mortgage, unpaid, repair, asking } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({ whendo, mortgage, unpaid, repair, asking }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, whendo, mortgage, unpaid, repair, asking]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="When do you need to sell your home ?"
            name="whendo"
            placeholder="Enter a reason"
            value={whendo.value}
            onChange={handleChange}
            error={!!whendo.error}
            helperText={whendo.error}
            required={whendo.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Mortgage"
            name="mortgage"
            placeholder="Enter your mortgage"
            value={mortgage.value}
            onChange={handleChange}
            error={!!mortgage.error}
            helperText={mortgage.error}
            required={mortgage.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Unpaid Tax"
            name="unpaid"
            placeholder="Enter your unpaid"
            value={unpaid.value}
            onChange={handleChange}
            error={!!unpaid.error}
            helperText={unpaid.error}
            required={unpaid.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Repair"
            name="repair"
            placeholder="Enter your repair"
            value={repair.value}
            onChange={handleChange}
            error={!!repair.error}
            helperText={repair.error}
            required={repair.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Asking price ?"
            name="asking"
            placeholder="Enter your asking"
            value={asking.value}
            onChange={handleChange}
            error={!!asking.error}
            helperText={asking.error}
            required={asking.required}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>



      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 45 }} color="success">
          Previous
        </Button>
        <Button
          variant="contained"
          disabled={isError()}
          color="success"
          onClick={!isError() ? handleNext  : () => null}
        >
          Next
        </Button>
      </Box>
    </>
  );
}
