// import React from "react";
import { motion } from "framer-motion";
import { FiSearch } from "react-icons/fi";

type searchProps = {
  searchString?: string;
  name: string;
  placeholder: string;
  type?: "dashboard" | "normal";
  onChange?: (e:string)=> void;
};

const SearchBox = ({ name, placeholder, searchString, type, onChange }: searchProps) => {
  if (type === "dashboard") {
    return (
      <div className="w-full rounded flex items-center p-1 px-2 bg-[#FAFAFA] border border-[#E6E6E6] focus:border-sky-400">
        <FiSearch className="text-[#4C4C4C] text-2xl" />
        <input
          className="outline-none p-2 w-full bg-transparent placeholder:text-[#4C4C4C]"
          placeholder={placeholder}
          onChange={(e)=> {if(onChange)onChange(e.target.value)}}
        />
      </div>
    );
  }
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.2 } }}
      exit={{ y: 20, opacity: 0 }}
      className="pt-2 relative text-gray-600"
    >
      <input
        className="w-full border-2 border-primary bg-white h-10 px-5 rounded-full text-sm focus:outline-none"
        type="search"
        value={searchString}
        name={name}
        placeholder={placeholder}
      />
    </motion.div>
  );
};

export default SearchBox;
