import { useState } from "react";
import { BiMinus } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import { motion } from "framer-motion";
import { textAnimate } from "../pages/Home2";
type QuestionProps = {
  question: string;
  answer: string;
};

const QuestionDropdown = ({ answer, question }: QuestionProps) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <motion.div
      onClick={() => {
        setIsOpened(!isOpened);
      }}
      variants={textAnimate}
      onBlur={() => {
        if (isOpened) setIsOpened(false);
      }}
      tabIndex={0}
      className="w-full bg-white my-4 mx-6 p-5 shadow-lg rounded-lg cursor-pointer"
    >
      <div className="flex items-center justify-between">
        <h3 className="text-base md:text-[18px] text-primary">{question}</h3>
        {/* Plus ICON */}
        {isOpened ? (
          <BiMinus size={22} color="#12DDD0" />
        ) : (
          <BsPlusLg className="" size={18} color="#12DDD0" />
        )}
      </div>
      {isOpened && (
        <motion.div className="mt-6">
          <p>{answer}</p>
        </motion.div>
      )}
    </motion.div>
  );
};

export default QuestionDropdown;
