// import { Form, Formik, FormikErrors } from "formik";
// import React, { useEffect, useState } from "react";
// import InputField from "../components/inputs/InputField";
// import PasswordInputField from "../components/inputs/PasswordInputField";
// import Container from "../components/utility/Container";
// import { Link, useNavigate } from "react-router-dom";
// import { motion } from "framer-motion";
// import AnimVariants from "../data/variants";
// import * as Yup from "yup";
// import http from "../utils/http";
// import { errorToast, successToast } from "../app/toasts";
// import Layout from "../components/Layout";
// import LoadingPage from "../components/LoadingPage";
// import { CustomAxiosError } from "../ts/interfaces/main";
// import Logo from "../utils/logo.svg";

// const SignUpSchema = Yup.object().shape({
//   username: Yup.string().required().min(4),
//   name: Yup.string().required(),
//   email: Yup.string().email().required(),
//   password: Yup.string()
//     .required()
//     .min(8, "Password cannot be less than 8 characters"),
//   password_confirmation: Yup.string()
//     .required()
//     .min(8, "Password cannot be less than 8 characters")
//     .oneOf([Yup.ref("password")], "Passwords do not match."),
// });
// interface SignUpValues {
//   username: string;
//   name: string;
//   email: string;
//   password: string;
//   password_confirmation: string;
// }
// const SignUp = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const handleSignUp = (
//     val: SignUpValues,
//     setErrors: (errors: FormikErrors<SignUpValues>) => void
//   ) => {
//     setLoading(true);

    // http
    //   .post("/api/v1/spectrum/admin/create", val)
    //   .then(() => {
    //     successToast("Account Created Successfully");
    //     navigate("/login");
    //   })
    //   .catch((err: CustomAxiosError) => {
    //     setErrors(err.data.errors);
    //     errorToast(err.data.message);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
//   };
//   return (
//     <Layout layout="none">
//       <motion.div
//         variants={AnimVariants}
//         initial="initial"
//         animate="animate"
//         exit="exit"
//         className="relative"
//       >
//         <svg
//           className="hidden lg:block z-0 absolute top-0 left-0"
//           width="672"
//           height="475"
//           viewBox="0 0 672 475"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             opacity="0.05"
//             d="M-83.9121 425.347C-27.9433 395.623 -6.7814 356.43 -20.4263 307.771C-40.8936 234.781 -18.503 188.186 43.1875 184.393C104.878 180.601 163.096 233.52 178.681 296.864C194.266 360.208 280.957 361.96 315.34 349.41C349.724 336.86 369.462 311.555 368.073 283.976C365.001 222.984 253.327 174.387 255.94 118.156C258.553 61.925 350.376 41.2739 407.979 91.9161C410.817 94.4111 413.708 96.7593 416.644 98.9654C475.674 143.318 553.026 130.213 588.396 97.3155C649.731 40.2688 621.761 -20.6983 523.35 -62.0658C375.196 -127.262 296.342 -198.724 286.789 -276.452"
//             stroke="url(#paint0_linear_42_293)"
//             strokeWidth="100"
//             strokeLinecap="round"
//           />
//           <defs>
//             <linearGradient
//               id="paint0_linear_42_293"
//               x1="-83.9121"
//               y1="425.347"
//               x2="372.999"
//               y2="-401.176"
//               gradientUnits="userSpaceOnUse"
//             >
//               <stop offset="0.0869954" stopColor="#DB2E7D" />
//               <stop offset="0.364583" stopColor="#7A0490" />
//               <stop offset="0.828125" stopColor="#FFAF00" />
//             </linearGradient>
//           </defs>
//         </svg>

//         <div className="flex z-10 relative">
//           <div className="min-h-screen flex flex-col w-full pb-20 lg:w-[55%]">
//             <div>
//               <Container className="w-10/12 lg:w-[93%] py-3">
//                 <Link
//                   to="/"
//                   className="flex max-w-fit gap-3 items-center justify-start"
//                 >
//                   <img
//                     src={Logo}
//                     className="w-[150px] md:w-auto"
//                     alt="Spectrum logo"
//                   />
//                 </Link>
//               </Container>
//             </div>
            
//             <div className="flex-1 lg:flex items-center justify-center">
//               <Container className="flex items-center justify-center">
//                 <div className="flex-1 space-y-3">
//                   <h1 className="my-10 font-bold text-lg lg:text-3xl">
//                     Let's get you started
//                   </h1>
//                   <Formik
//                     validationSchema={SignUpSchema}
//                     initialValues={{
//                       username: "",
//                       name: "",
//                       email: "",
//                       password: "",
//                       password_confirmation: "",
//                     }}
//                     onSubmit={(e, { setErrors }) => {
//                       handleSignUp(e, setErrors);
//                     }}
//                   >
//                     {() => (
//                       <Form className="space-y-3 w-full lg:w-[400px] mx-auto">
//                         <InputField
//                           name="username"
//                           label="Username"
//                           placeholder="john1"
//                         />
//                         <InputField
//                           name="name"
//                           type={"text"}
//                           label="Name"
//                           placeholder="John Doe"
//                         />
//                         <InputField
//                           name="email"
//                           type={"email"}
//                           label="Email Address"
//                           placeholder="yourname@gmail.com"
//                         />
//                         <PasswordInputField
//                           placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
//                           name="password"
//                           label="Password"
//                         />
//                         <PasswordInputField
//                           placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
//                           name="password_confirmation"
//                           label="Confirm Password"
//                         />
//                         <div className="flex space-y-2 flex-col">
//                           {/* <p className={classNames('text-[#CCCCCC] text-xs text-left')}>Password must contain a minimum of 8 characters</p> */}
//                           {/* <p className={classNames('text-[#CCCCCC] text-xs text-left')}>Password must contain at least one symbol e.g. @, !</p> */}
//                         </div>

//                         {loading ? (
//                           <LoadingPage />
//                         ) : (
//                           <button
//                             type="submit"
//                             className="py-2 bg-primary rounded-3xl text-white w-full font-bold"
//                           >
//                             Sign Up
//                           </button>
//                         )}
//                         {/* <GoogleButton title='Sign Up with Google' className='mx-auto' /> */}
//                         <p className="text-xs text-center font-medium text-[#595959]">
//                           Already have an account?{" "}
//                           <Link
//                             to="/login"
//                             className="text-primary cursor-pointer"
//                           >
//                             Sign In
//                           </Link>
//                         </p>
//                       </Form>
//                     )}
//                   </Formik>
//                 </div>
//               </Container>
//             </div>
//           </div>
//           <div className="hidden fixed right-0 lg:flex flex-col w-[45%] min-h-screen bg-gradient-to-br from-[#845824] to-[#467B19]">
//             <div>
//               {/* <Container className='w-10/12 lg:w-[93%]'>
//           </Container> */}
//             </div>
//             <div className="flex-1 flex items-center justify-center">
//               <Container className="lg:w-[11/12]">
//                 <h1 className="text-[#E4E4E4] font-bold text-[4rem]">
//                   Go Boundryless With Us
//                 </h1>
//               </Container>
//             </div>
//           </div>
//         </div>
//       </motion.div>
//     </Layout>
//   );
// };

// export default SignUp;

// import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import StepForm from '../components/StepForm'
import Header from '../components/Header'
import Footer from '../components/Home/Footer'

/**
 * Old js version
 * https://codesandbox.io/s/react-material-ui-step-form-forked-578lz
 *
 * Repo: https://github.com/awran5/react-material-ui-step-form
 */

const theme = createTheme()

const SignUp = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Header />
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <StepForm />
      </Paper>
    
    </Container>
    <Footer />
  </ThemeProvider>
)

export default SignUp