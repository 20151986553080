import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { createContext } from "react";
import { configs } from "../config";
import http from "../utils/http";
import { CtxProviderProps } from "./PageAnimatorContextProvider";

window.Pusher = Pusher;

function createSocketConnection() {
  if (!window.Echo) {
    const EchoInstance = new Echo({
      broadcaster: "pusher",
      disableStats: false,
      key: configs.app.PUSHER_APP_KEY,
      forceTLS: false,
      cluster: configs.app.cluster,
      authorizer: (channel: any) => {
        return {
          authorize: (
            socketId: string,
            callback: (e: boolean, data2: any) => void
          ) => {
            http
              .post("/chat/auth", {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .then((data) => {
                callback(false, data);
              })
              .catch((error) => {
                callback(true, error);
              });
          },
        };
      },
    });
    window.Echo = EchoInstance;
  }
}
const listen = (
  callBack: (payload: any) => void,
  channel: string,
  event: string,
  privateChanel = true
) => {
  createSocketConnection();
  if (privateChanel) {
    // hack to check if the name exists
    window.Echo.private(channel).listen(event, callBack);
  } else {
    window.Echo.channel(channel).listen(event, callBack);
  }

  return function cleanUp() {
    if (privateChanel) {
      // hack to check if the name exists
      window.Echo.private(channel).stopListening(event, callBack);
    } else {
      window.Echo.channel(channel).stopListening(event, callBack);
    }
  };
};
export const SocketConnectionContex = createContext({ listen });

const SocketClientProvider = ({ children }: CtxProviderProps) => {
  return (
    <SocketConnectionContex.Provider value={{ listen }}>
      {children}
    </SocketConnectionContex.Provider>
  );
};

export default SocketClientProvider;
