import { motion } from "framer-motion";
// import React from "react";
import { MdDelete } from "react-icons/md";
import { GiftCardValue } from "../features/asset/thunkActions";

type GiftCardBoxProps = {
  giftcard: GiftCardValue;
  onClick?: () => void;
  editable?: boolean;
  onEdit?: (e: GiftCardValue) => void;
  onDelete?: (e: GiftCardValue) => void;
};
const giftcardImg ="https://media.istockphoto.com/photos/gift-card-isolated-picture-id1147628749?k=20&m=1147628749&s=612x612&w=0&h=grgVUKeZqkKEqq_lKIEosyiMIVt3Cdj3iSonPmt1AWo=";

const GiftcardBox = ({
  giftcard,
  onClick,
  editable,
  onDelete,
}: GiftCardBoxProps) => {
  return (
    <motion.div
      onClick={() => {
        if (onClick) onClick();
      }}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.3 } }}
      exit={{ y: 20, opacity: 0 }}
      className="bg-white relative cursor-pointer w-full md:w-3/5 lg:w-[40%] shadow-lg rounded px-5 py-3 flex items-center justify-between"
    >
      <div className="flex space-x-2 items-center justify-start">
        <img
          src={giftcard.image_url || giftcardImg}
          className="max-w-[26px] max-h-[26px]"
          alt={`${giftcard.name} symbol`}
        />
        <div className="">
          <h4 className="text-[13px] font-semibold">{giftcard.name}</h4>
        </div>
      </div>

      {editable && (
        <div className="group w-full px-4 z-10 bg-transparent ease-in duration-100 h-full left-0 rounded absolute flex items-center justify-end space-x-2 hover:bg-primary/90">
          {/* <MdEdit
            onClick={() => {
              if (onEdit) onEdit(giftcard);
            }}
            className="text-transparent text-2xl group-hover:text-white hover:scale-105 ease-in duration-100"
          /> */}
          <MdDelete
            onClick={() => {
              if (onDelete) onDelete(giftcard);
            }}
            className="text-transparent text-2xl group-hover:text-white hover:scale-105 ease-in duration-100"
          />
        </div>
      )}
    </motion.div>
  );
};

export default GiftcardBox;
