import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Container from "../utility/Container";
// import CTAButton from "../utility/CTAButton";
import WhatsAppModal from "../utility/WhatsAppModal";
// import Logo from "../../utils/logo.svg";
import Grid from "@mui/material/Grid";
// import { PrivacyPolicyModal } from "../../pages/privacyPolicy";

import { FaFacebook, FaInstagramSquare, FaTwitter, FaYoutube, FaSnapchat } from "react-icons/fa";
// FaTiktok,

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/privacy');
  };

  const [type, setType] = useState<"buy" | "sell" | "giftcard" | null>(null);
  return (
    <div
      className="w-full shadow-inner py-[60px] bg-[#00B776]"
      id="contactUs"
    >
      <Container>
        {type && (
          <WhatsAppModal
            type={type}
            onClose={() => {
              setType(null);
            }}
          />
        )}

        <div className="flex flex-wrap items-center justify-around sm:justify-start ">

          <div className="sm:hidden"></div>
          <div className="flex flex-wrap items-start justify-center md:justify-between md:w-full lg:flex-1">
            <Grid container spacing={0}>
              <Grid item md={5} style={{ margin: 'auto' }}>

                <p className="text-[#ffff]  font-medium text-left -mt-2 -mb-10 text-[14px]">
                  Copyright {new Date().getFullYear()} ©Amazing Properties | All rights reserved.

                </p>

              </Grid>
              <Grid item md={7} style={{ marginLeft: 'auto' }} >

                <Grid container spacing={0}>
                  <Grid item xs={7} >
                    <div className="flex  flex-col lg:flex-row items-center justify-center">
                      <div className="space-y-3  flex flex-col items-center justify-center py-4">
                        <a href="/" >
                          <div className='flex items-center justify-center text-[#ffff] font-bold w-5 h-5 p-5 rounded-full'>Home</div>
                        </a>
                        {/* <h1 className='font-semibold text-[#00B776]  text-center'>Home</h1> */}

                      </div>
                      <div className="space-y-3 flex flex-col items-center justify-center py-4" style={{ paddingLeft: '20px' }}>
                        <a href="/aboutus" target="_blank">
                          <div className='flex items-center justify-center text-[#ffff]  font-bold  rounded-full'>About Us</div>
                        </a>
                        {/* <h1 className='font-semibold text-[#00B776] text-center'>About Us</h1> */}

                      </div>
                      <div className="space-y-3 flex flex-col items-center justify-center py-8" style={{ paddingLeft: '20px' }}>
                        <a href="/#faq" >
                          <div className='flex items-center justify-center text-[#ffff]  font-bold w-5 h-5 p-5 rounded-full' >FAQs</div>

                        </a>
                        {/* <h1 className='font-semibold text-[#00B776]  text-center'>FAQ</h1> */}

                      </div>
                      <div className="space-y-3 flex flex-col items-center justify-center py-4" style={{ paddingLeft: '20px' }}>
                        <a href="/#contactus" >
                          <div className='flex items-center justify-center text-[#ffff]  font-bold  rounded-full'>Contact Us</div>

                        </a>
                        {/* <h1 className='font-semibold text-[#00B776]  text-center'>Contact</h1> */}

                      </div>
                      <div className="space-y-3 flex flex-col items-center justify-center py-4" style={{ paddingLeft: '20px' }}>
                        <button onClick={handleNavigation} className='flex items-center justify-center text-[#ffff] font-bold rounded-full'>
                          Privacy Policy
                        </button>
                        {/* <PrivacyPolicyModal 
                          trigger={
                            <button className="text-[#ffff]  font-bold  hover:text-white">
                              Privacy Policy
                            </button>
                          } 
                        /> */}
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={5} style={{ margin: 'auto' }}>

                    <div className="flex  flex-col lg:flex-row items-center justify-center">

                      <div className="space-y-3 flex flex-col items-center justify-center py-4 " style={{ paddingLeft: '10px' }}>
                        <a href="https://web.facebook.com/profile.php?id=61552706782079" target="_blank" rel="noreferrer">
                          <div className='flex items-center justify-center  bg-[#ffff] font-bold  border-[1px] rounded-lg' style={{ height: '34px', width: '34px' }}><FaFacebook color="grey" size={22} /></div>

                        </a>

                      </div>
                      <div className="space-y-3 flex flex-col items-center justify-center py-4 rounded-2x1" style={{ paddingLeft: '10px' }}>
                        <a href="https://twitter.com/AmazingPro15202" target="_blank" rel="noreferrer">
                          <div className='flex items-center justify-center   font-bold w-45 bg-[#ffff] border-[1px] rounded-lg  ' style={{ height: '34px', width: '34px' }}><FaTwitter color="grey" size={22} /></div>
                        </a>


                      </div>
                      <div className="space-y-3 flex flex-col items-center justify-center py-4 rounded-2x1" style={{ paddingLeft: '10px' }}>
                        <a href="https://www.instagram.com/abinvestmentgroups/" target="_blank" rel="noreferrer">
                          <div className='flex items-center justify-center  font-bold w-45  bg-[#ffff] border-[1px] rounded-lg ' style={{ height: '34px', width: '34px' }}><FaInstagramSquare color="grey" size={22} /></div>

                        </a>

                      </div>
                      <div className="space-y-3 flex flex-col items-center justify-center py-4 rounded-2x1" style={{ paddingLeft: '10px' }}>
                        <a href="https://www.youtube.com/channel/UCVHPIYG8V42lNIeq_zGWLyw" target="_blank" rel="noreferrer">
                          <div className='flex items-center justify-center   font-bold w-45  bg-[#ffff] border-[1px] rounded-lg ' style={{ height: '34px', width: '34px' }}><FaYoutube color="grey" size={22} /></div>
                        </a>


                      </div>

{/*                       <div className="space-y-3 flex flex-col items-center justify-center py-4 rounded-2x1" style={{ paddingLeft: '10px' }}>
                        <a href="https://www.tiktok.com/@sell_mi_house_fast?is_from_webapp=1&sender_device=pc" target="_blank">
                          <div className='flex items-center justify-center  bg-[#ffff] font-bold w-45  border-[1px] rounded-lg' style={{ height: '34px', width: '34px' }}><FaTiktok color="grey" size={22} /></div>

                        </a> 

                      </div>*/}
                      <div className="space-y-3 flex flex-col items-center justify-center py-4 rounded-2x1" style={{ paddingLeft: '10px' }}>
                        <a href="https://www.snapchat.com/add/joe_brian66" target="_blank" rel="noreferrer">
                          <div className='flex items-center justify-center  bg-[#ffff] font-bold w-45 border-[1px] rounded-lg  ' style={{ height: '34px', width: '34px' }}><FaSnapchat color="grey" size={22} /></div>

                        </a>

                      </div>

                    </div>
                  </Grid>
                </Grid>




              </Grid>

            </Grid>



          </div>
        </div>

      </Container>
    </div>
  );
};

export default Footer;




