import React from "react";
import {
  Routes, Route,//Navigate
} from "react-router-dom";
import ChooseCurrency from "../../pages/ChooseCurrency";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
// import SignUp from "../../pages/SignUp";
import ChatSection from "../../pages/ChatSection";
// import GiftCard from "../../pages/GiftCard";
import Admin from "../../pages/admin";
import Settings from "../../pages/admin/Settings";
import Transactions from "../../pages/admin/Transactions";
import AdminChat from "../../pages/admin/AdminChat";
import TransactionStatus from "../../components/TransactionStatus";
import TradeStatus from "../../pages/admin/TradeStatus";
import AdminCheck from "../../pages/admin/AdminCheck";
import NotFountPage from "../../pages/404";
// import Crypto from "../../pages/Crypto";
//import GiftCardTrades from "../../pages/admin/GiftCardTrades";
import About from "../../pages/About";
// import Home2 from "../../pages/Home2";
import Home3 from "../../pages/Home3";
import Contact from "../../pages/Contact";
import SignUp from "../../pages/SignUp";
import Features from "../../pages/Features";
import PrivacyPolicy from "../../pages/privacyPolicy";
//import authUser from "./protectedRoutes";
//import {  useAppSelector } from "../../app/hooks";
// import Onboarding from "../../components/Onboarding";





const AnimatedRoutes = () => {
  //const user = useAppSelector((state) => state.user);

  //  const  authUser =  () => {


  //   if(!user.data) {
  //     return <Navigate to="/login"  />
  // }else{
  //   return(

  //   )
  // }
  // }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/sell" element={<SignUp />} />
      <Route path="/sucess" element={<TransactionStatus />} />
      {/* <Route path="/crypto/buy" element={<Crypto type="buy" />} />
      <Route path="/crypto/sell" element={<Crypto type="sell" />} /> */}
      {/* <Route path="/sell/giftcard" element={<GiftCard />} /> */}
      <Route path="/chat" element={<ChatSection />} />
      <Route path="/choose" element={<ChooseCurrency />} />
      <Route path="/aboutus" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/admin" element={<AdminCheck />}> {/* //AdminCheck */}
        <Route path="/admin/" element={<Admin />} />
        <Route path="/admin/settings" element={<Settings />} />
        <Route path="/admin/transactions" element={<Transactions />} />
        {/* <Route
          path="/admin/transactions/giftcard"
          element={<GiftCardTrades />}
        /> */}
        <Route path="/admin/chat" element={<AdminChat />} />
        <Route path="/admin/trade/:status" element={<TradeStatus />} />
      </Route>
      <Route path="/home" element={<Home />} />

      <Route path="/main" element={<Home3 />} />

      <Route path="/" element={<Features />} />
      <Route path="*" element={<NotFountPage />} />
    </Routes>
  );
};

export default React.memo(AnimatedRoutes);
