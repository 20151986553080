import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const PrivacyPolicy: React.FC = () => {
  const [policyContent, setPolicyContent] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/privacy-policy.md');
        
        if (!response.ok) {
          throw new Error('Failed to fetch privacy policy');
        }
        
        const content = await response.text();
        setPolicyContent(content);
        setError(null);
      } catch (error) {
        console.error('Failed to load privacy policy:', error);
        setError('Privacy policy could not be loaded. Please try again later.');
        setPolicyContent('');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrivacyPolicy();
  }, []);

  return (
    <div className="min-h-screen bg-[#00B776] flex justify-center items-center py-8 px-4">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-4xl w-full">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        
        <p className="text-sm text-gray-500 mb-6">
          Last Updated: {new Date().toLocaleDateString()}
        </p>

        {isLoading ? (
          <div className="text-center py-8">
            <p>Loading privacy policy...</p>
          </div>
        ) : error ? (
          <div className="bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded">
            {error}
          </div>
        ) : (
          <div className="prose max-w-full">
            <ReactMarkdown 
              remarkPlugins={[remarkGfm]}
              components={{
                a: ({node, ...props}) => (
                  <a 
                    {...props} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-600 hover:underline"
                  >
                    {props.children}
                  </a>
                )
              }}
            >
              {policyContent}
            </ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;