// import React from "react";
import { motion } from "framer-motion";
import { btcImgSrc } from "./Home/Trade";
import { MdEdit, MdDelete } from "react-icons/md";
import { CryptoCurrency } from "../features/asset/thunkActions";
import { CurrencyFormat } from "../utils";

export type CryptoBoxProps = {
  crypto: CryptoCurrency;
  onClick?: () => void;
  editable?: boolean;
  onEdit?: (e: CryptoCurrency) => void;
  onDelete?: (e: CryptoCurrency) => void;
  tradeType?: "buy" | "sell" | "both";
};

const CryptoBox = ({
  crypto,
  onClick,
  onEdit,
  onDelete,
  tradeType = "buy",
  editable = false,
}: CryptoBoxProps) => {
  return (
    <motion.div
      onClick={() => {
        if (onClick) onClick();
      }}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.3 } }}
      exit={{ y: 20, opacity: 0 }}
      className="bg-white relative cursor-pointer w-full md:w-3/5 lg:w-[40%] shadow-lg rounded px-5 py-3 flex items-center justify-between"
    >
      <div className="flex space-x-2 items-center justify-start">
        <img
          src={crypto.image_url || btcImgSrc}
          className="max-w-[26px] max-h-[26px]"
          alt={`${crypto.name} symbol`}
        />
        <div className="">
          <h4 className="text-[13px] font-semibold">{crypto.name}</h4>
          <h5 className="text-[10px] font-semibold">
            {crypto.symbol.toUpperCase()}
          </h5>
        </div>
      </div>
      <div className="">
        <p className="text-black text-xs self-center capitalize">
          {tradeType === "both" ? "" : tradeType} rate
        </p>
        <p className="text-black font-semibold self-center">
          {tradeType === "buy" ? (
            <span>₦{CurrencyFormat(crypto.buy_rate)}</span>
          ) : tradeType === "sell" ? (
            <span>₦{CurrencyFormat(crypto.sell_rate)}</span>
          ) : (
            <>
              <span className="text-green-600">
                ₦{CurrencyFormat(crypto.buy_rate)}
              </span>{" "}
              <span className="text-red-600 ml-3">
                ₦{CurrencyFormat(crypto.sell_rate)}
              </span>
            </>
          )}
        </p>
      </div>
      {editable && (
        <div className="group w-full px-4 z-10 bg-transparent ease-in duration-100 h-full left-0 rounded absolute flex items-center justify-end space-x-2 hover:bg-primary/90">
          <MdEdit
            onClick={() => {
              if (onEdit) onEdit(crypto);
            }}
            className="text-transparent text-2xl group-hover:text-white hover:scale-105 ease-in duration-100"
          />
          <MdDelete
            onClick={() => {
              if (onDelete) onDelete(crypto);
            }}
            className="text-transparent text-2xl group-hover:text-white hover:scale-105 ease-in duration-100"
          />
        </div>
      )}
    </motion.div>
  );
};

export default CryptoBox;
