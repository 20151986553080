// import React from "react";
import CryptoAsset from "../../components/Admin/CryptoAsset";
import Currency from "../../components/Admin/Currency";
import Networks from "../../components/Admin/Networks";
import InfoPane from "./InfoPane";
import Profile from "../../components/Admin/Profile";
import GiftCard from "../../components/Admin/GiftCard";

const Settings = () => {
  return (
    <InfoPane>
      <CryptoAsset />
      <div className="my-10" />
      <GiftCard />
      <div className="my-10" />
      <Networks />
      <div className="my-10" />
      <Currency />
      <div className="my-10" />
      <Profile />
    </InfoPane>
  );
};

export default Settings;
