// import React from 'react'

const GirlSvg = ({className}:{className?: string}) => {
    return (
        <svg className={className} width="466" height="519" viewBox="0 0 466 519" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M437.459 296.638C437.459 407.297 347.793 497.004 237.183 497.004C126.574 497.004 36.9071 407.297 36.9071 296.638C36.9071 185.978 126.574 96.2711 237.183 96.2711C347.793 96.2711 437.459 185.978 437.459 296.638Z" fill="#F2F2F2" />
            <path d="M239.628 27.4216C241.117 42.3061 230.262 55.5816 215.382 57.0736C200.502 58.5656 187.233 47.7091 185.743 32.8247C184.254 17.9402 195.109 4.66478 209.989 3.17278C224.869 1.68078 238.139 12.5372 239.628 27.4216Z" fill="black" />
            <path d="M282.424 362.873L188.765 364.466C152.803 418.909 164.805 508.804 164.805 508.804L170.306 609.148C191.432 609.21 212.541 608.987 233.637 608.563L232.733 465.649L239.246 464.649L244.483 608.321C266.063 607.814 287.63 607.118 309.186 606.239L310.71 514.745C316.511 401.821 282.424 362.873 282.424 362.873Z" fill="black" />
            <path d="M152.409 215.118L226.573 184.04L299.693 205.094C303.635 206.314 309.709 213.161 308.584 217.134C301.416 242.458 277.829 324.546 282.041 363.508L235.263 365.849L188.484 368.191C188.8 328.986 157.702 249.35 148.234 224.827C146.751 220.986 148.612 216.709 152.409 215.118Z" fill="#8C96FD" />
            <path d="M236.725 152.051C236.725 152.051 232.689 158.818 226.798 160.529C220.907 162.239 214.889 158.209 214.813 158.16C214.736 158.112 209.79 153.398 209.79 153.398L206.72 192.349L207.532 193.105C218.173 203.006 232.757 202.418 242.46 191.697L243.879 190.128L236.725 152.051Z" fill="#FFAF87" />
            <path d="M203.899 153.754L241.577 179.328L236.325 149.883L203.899 153.754Z" fill="#EB855A" />
            <path d="M206.722 192.35L197.71 196.128C197.71 196.128 201.068 217.263 226.9 215.971C252.732 214.679 254.747 192.15 254.747 192.15L242.624 188.796L206.722 192.35Z" fill="#FFAF87" />
            <path d="M152.415 215.11C152.415 215.11 136.966 214.634 102.956 349.206L142.587 361.639L183.465 257.124L152.415 215.11Z" fill="#8C96FD" />
            <path d="M260.064 322.063L217.458 323.231C216.775 323.249 216.206 322.71 216.187 322.027L214.086 245.086C214.067 244.402 214.606 243.833 215.289 243.814L257.895 242.647C258.578 242.628 259.148 243.167 259.166 243.851L261.267 320.792C261.286 321.475 260.747 322.045 260.064 322.063Z" fill="#4C4C4C" />
            <path d="M260.064 322.064L217.458 323.231C216.775 323.25 216.206 322.711 216.187 322.027L214.086 245.086C214.067 244.402 214.606 243.833 215.289 243.814L257.895 242.647C258.578 242.628 259.148 243.167 259.166 243.851L261.267 320.792C261.286 321.475 260.747 322.045 260.064 322.064ZM260.167 325.823L217.561 326.99C216.877 327.009 216.308 326.47 216.29 325.787L214.188 248.846C214.17 248.163 214.709 247.593 215.392 247.574L257.998 246.407C258.681 246.388 259.25 246.927 259.269 247.611L261.37 324.551C261.389 325.235 260.85 325.804 260.167 325.823Z" fill="black" />
            <path d="M246.5 296.194C245.228 294.833 244.249 294.982 244.249 294.982C244.249 294.982 246.466 293.08 245.454 289.974C245.454 289.974 244.792 288.067 243.255 287.85C243.255 287.85 244.213 282.452 239.652 282.326L239.757 282.167C240.808 280.584 240.613 278.482 239.289 277.119C237.864 275.653 235.583 275.481 233.956 276.719C229.747 279.923 220.382 285.649 214.955 286.214C215.11 284.214 215.264 280.569 214.683 277.162C214.032 273.331 210.719 271.269 207.737 272.709C205.208 273.929 204.401 276.947 205.049 280.172C205.253 281.187 205.309 282.188 205.277 283.202C205.264 283.217 205.247 283.225 205.234 283.24C201.159 287.856 196.213 296.016 192.929 313.501L197.651 330.985C197.651 330.985 239.327 314.203 246.786 299.525C246.786 299.525 247.772 297.556 246.5 296.194Z" fill="#FFAF87" />
            <path d="M115.966 333.099L191.147 296.744L194.545 305.632L204.896 332.713L136.524 375.225C124.112 382.375 108.249 376.844 102.971 363.525C98.2203 351.535 104.021 337.955 115.966 333.099Z" fill="#8C96FD" />
            <path d="M164.373 309.688L138.404 322.247" stroke="black" strokeWidth="0.338" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M185.99 344.47L204.894 332.716L203.308 328.567" stroke="black" strokeWidth="0.338" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M176.708 303.723L191.143 296.743L194.542 305.632" stroke="black" strokeWidth="0.338" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M294.891 203.718C294.891 203.718 312.528 199.712 403.13 304.829L368.235 329.897L284.934 254.735L294.891 203.718Z" fill="#8C96FD" />
            <path d="M299.719 421.474L301.706 406.904L306.55 396.853L338.264 361.867L347.632 385.377L328.762 402.782L323.859 418.655C323.859 418.655 321.475 426.376 328.931 431.307C332.148 433.435 333.811 437.238 332.486 440.861C331.219 444.324 327.732 446.436 324.078 445.953C313.687 444.58 294.693 439.588 299.719 421.474Z" fill="#FFAF87" />
            <path d="M365.825 307.732L318.654 377.633L343.429 404.378L403.326 335.844C412.678 324.991 410.226 308.365 398.141 300.678C387.261 293.758 372.837 296.907 365.825 307.732Z" fill="#8C96FD" />
            <path d="M353.613 324.235L365.823 307.73" stroke="black" strokeWidth="0.338" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M193.231 133.644C194.201 141.183 188.76 146.91 181.223 147.882C173.685 148.854 166.615 141.881 165.645 134.341C164.675 126.802 170.29 122.724 177.827 121.752C185.365 120.78 192.261 126.105 193.231 133.644Z" fill="#FFAF87" />
            <path d="M170.708 134.349C170.708 134.349 175.886 131.151 182.022 140.092" stroke="black" strokeWidth="0.321" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M180.934 146.733C181.088 148.268 179.968 149.637 178.434 149.791C176.899 149.944 175.53 148.825 175.376 147.29C175.223 145.755 176.343 144.386 177.877 144.232C179.412 144.079 180.78 145.198 180.934 146.733Z" fill="#8C96FD" />
            <path d="M244.75 127.3C245.292 134.882 251.76 139.415 259.34 138.871C266.92 138.326 272.468 130.086 271.926 122.504C271.383 114.922 265.073 112.041 257.493 112.585C249.912 113.129 244.207 119.717 244.75 127.3Z" fill="#FFAF87" />
            <path d="M266.966 123.52C266.966 123.52 261.256 121.413 257.014 131.394" stroke="black" strokeWidth="0.321" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M264.086 138.401C264.239 139.936 263.119 141.305 261.585 141.459C260.05 141.612 258.682 140.493 258.528 138.958C258.375 137.423 259.494 136.054 261.028 135.9C262.563 135.747 263.932 136.866 264.086 138.401Z" fill="#8C96FD" />
            <path d="M266.299 87.0328C269.099 115.028 248.006 145.264 221.55 147.917C195.093 150.57 172.759 123.012 169.958 95.0172C167.157 67.0213 185.793 42.4428 212.25 39.79C238.707 37.1372 263.498 59.037 266.299 87.0328Z" fill="black" />
            <path d="M257.603 93.5505C259.221 108.936 258.061 120.139 256.73 129.806C255.428 139.271 238.734 156.089 238.734 156.089C229.75 162.588 219.754 163.782 209.489 159.514C199.137 155.209 190.138 146.695 186.987 137.017C181.257 119.416 178.972 101.837 178.972 101.837C178.972 101.837 170.46 60.6366 212.945 56.9227C255.43 53.2088 257.603 93.5505 257.603 93.5505Z" fill="#FFAF87" />
            <path d="M172.491 91.9779C172.491 91.9779 210.607 94.0921 241.278 59.3563C241.278 59.3563 206.202 43.6494 190.187 54.6724C174.173 65.695 172.491 91.9779 172.491 91.9779Z" fill="black" />
            <path d="M228.428 69.4329C228.428 69.4329 232.99 92.566 261.293 85.4762C261.293 85.4762 253.744 39.3403 228.428 69.4329Z" fill="black" />
            <path d="M215.79 128.641L216.538 136.12L222.051 135.567" stroke="black" strokeWidth="0.338" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M227.061 117.928C227.061 117.928 226.618 112.941 236.155 112.688C244.717 112.461 246.066 116.176 246.066 116.176C246.066 116.176 245.221 123.704 235.92 124.795C226.62 125.886 227.061 117.928 227.061 117.928Z" fill="white" />
            <path d="M241.917 117.488C242.269 121.297 239.854 124.632 236.524 124.938C233.193 125.244 230.208 122.404 229.856 118.595C229.504 114.786 231.919 111.451 235.249 111.145C238.58 110.84 241.565 113.679 241.917 117.488Z" fill="black" />
            <path d="M226.673 116.215C226.673 116.215 229.436 113.167 235.247 112.334C242.735 111.262 246.066 116.176 246.066 116.176" stroke="black" strokeWidth="0.742" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M206.671 119.97C206.671 119.97 206.118 114.994 196.72 116.639C188.283 118.117 187.697 122.026 187.697 122.026C187.697 122.026 189.178 129.321 198.51 128.543C207.842 127.765 206.671 119.97 206.671 119.97Z" fill="white" />
            <path d="M191.821 122.509C192.23 126.312 195.257 129.102 198.582 128.74C201.908 128.378 204.271 125.002 203.862 121.199C203.452 117.395 200.424 114.606 197.099 114.968C193.774 115.33 191.411 118.706 191.821 122.509Z" fill="black" />
            <path d="M206.713 118.215C206.713 118.215 203.4 115.775 197.54 116.112C189.988 116.548 187.697 122.025 187.697 122.025" stroke="black" strokeWidth="0.742" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M193.366 98.7591C193.366 98.7591 187.262 100.499 183.94 103.149C181.894 104.779 182.402 107.283 185.397 107.452C187.253 107.557 192.141 106.442 195.364 105.507C203.257 103.218 206.478 99.759 205.662 98.2565C204.514 96.1431 197.462 97.3246 193.366 98.7591Z" fill="black" />
            <path d="M236.695 94.457C236.695 94.457 242.949 95.3166 246.546 97.4725C248.761 98.8001 248.543 101.355 245.592 101.948C243.763 102.316 238.787 101.904 235.483 101.434C227.393 100.284 223.804 97.3098 224.442 95.7033C225.34 93.4445 232.469 93.6152 236.695 94.457Z" fill="black" />
            <path d="M215.914 145.021L221.981 144.061C221.981 144.061 225.076 148.024 220.084 148.987C214.289 150.108 215.914 145.021 215.914 145.021Z" fill="white" />
            <path d="M196.486 114.97C190.372 116.259 187.392 121.654 187.392 121.654C187.392 121.654 188.335 119.8 190.421 118.988C193.654 117.625 196.725 115.853 201.697 115.737C204.712 115.578 207.542 118.253 207.064 116.723C206.585 115.193 200.657 114.09 196.486 114.97Z" fill="#F49F75" />
            <path d="M234.917 111.322C228.688 112.591 225.593 117.32 225.593 117.32C225.593 117.32 225.623 117.441 227.986 115.959C230.473 114.467 233.769 110.981 241.982 113.145C244.709 113.733 246.689 116.099 246.211 114.569C245.732 113.039 239.092 110.467 234.917 111.322Z" fill="#F49F74" />
            <path d="M4.47021 317.058C4.47021 341.818 24.5333 361.89 49.2826 361.89C74.0319 361.89 94.0962 341.818 94.0962 317.058C94.0962 292.297 74.0319 272.225 49.2826 272.225C24.5333 272.225 4.47021 292.297 4.47021 317.058Z" fill="#FD7648" />
            <path d="M51.6695 334.653V340.195H45.6812V334.853C40.6908 334.503 35.9506 333.056 33.0562 331.059L36.2995 323.77C39.3934 325.817 43.7847 327.214 47.8271 327.214C51.9189 327.214 53.5159 326.066 53.5159 324.369C53.5159 318.827 33.6551 322.871 33.6551 309.891C33.6551 304.399 37.5969 299.756 45.6812 298.758V293.266H51.6695V298.658C55.3623 298.958 59.0052 299.956 61.7996 301.603L58.7557 308.942C55.4123 307.145 52.1184 306.246 49.0745 306.246C44.9327 306.246 43.4358 307.644 43.4358 309.391C43.4358 314.734 63.2465 310.74 63.2465 323.62C63.2465 328.912 59.4542 333.506 51.6695 334.653Z" fill="white" />
            <path d="M416.591 127.838C416.591 152.133 396.904 171.828 372.62 171.828C348.336 171.828 328.649 152.133 328.649 127.838C328.649 103.543 348.336 83.8478 372.62 83.8478C396.904 83.8478 416.591 103.543 416.591 127.838Z" fill="#FDC078" />
            <path d="M387.787 116.881C384.191 113.358 378.431 113.419 374.914 117.017L372.595 119.386L370.209 117.034C366.628 113.502 360.863 113.549 357.333 117.132C353.807 120.717 353.849 126.483 357.435 130.013L366.29 138.739C366.309 138.757 366.321 138.779 366.34 138.798C367.753 140.183 369.501 141.012 371.318 141.292C374.12 141.722 377.08 140.842 379.213 138.663L387.925 129.762C391.442 126.167 391.381 120.401 387.787 116.881Z" fill="white" />
            <path d="M436.093 461.289H427.378C427.139 461.289 426.944 461.094 426.944 460.854V452.51C426.944 452.27 427.139 452.075 427.378 452.075H436.093C436.333 452.075 436.527 452.27 436.527 452.51V460.854C436.527 461.094 436.333 461.289 436.093 461.289Z" fill="#FD7648" />
            <path d="M459.291 162.323H450.576C450.336 162.323 450.142 162.128 450.142 161.888V153.544C450.142 153.304 450.336 153.109 450.576 153.109H459.291C459.531 153.109 459.725 153.304 459.725 153.544V161.888C459.725 162.128 459.531 162.323 459.291 162.323Z" fill="#FD7648" />
            <path d="M10.1419 232.334H1.42808C1.18821 232.334 0.993286 232.14 0.993286 231.9V223.556C0.993286 223.316 1.18821 223.121 1.42808 223.121H10.1419C10.3818 223.121 10.5767 223.316 10.5767 223.556V231.9C10.5767 232.14 10.3818 232.334 10.1419 232.334Z" fill="#FD7648" />
            <path d="M458.564 469.556C458.564 469.556 449.864 469.556 437.686 469.556" stroke="#41C29F" strokeWidth="13" strokeLinecap="round" />
        </svg>

    )
}

export default GirlSvg