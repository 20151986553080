import { useCallback, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormHelperText from "@mui/material/FormHelperText";
// import Checkbox from "@mui/material/Checkbox";
import { AppContext } from "../Context";

export default function SecondStep() {
  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant,
    margin
  } = useContext(AppContext);
  const { propertytype, bedrooms, bathroom, footage, basement, pool, floors, year } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({ propertytype, bedrooms, bathroom, footage, basement, pool, floors, year }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, propertytype, bedrooms, bathroom, footage, basement, pool, floors, year]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Property Type?  "
            name="propertytype"
            placeholder="Enter your property type"
            value={propertytype.value}
            onChange={handleChange}
            error={!!propertytype.error}
            helperText={propertytype.error}
            // required={propertytype.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            label="Bedrooms "
            name="bedrooms"
            placeholder="Must have a door, closet, and window."
            defaultValue={bedrooms.value}
            onChange={handleChange}
            // required={bedrooms.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Bathrooms "
            name="bathroom"
            placeholder="Must have a toilet and a shower/tub."
            value={bathroom.value}
            onChange={handleChange}
            error={!!bathroom.error}
            helperText={bathroom.error}
            // required={bathroom.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Square footage (above ground)*"
            name="footage"
            placeholder="Don’t include basements."
            value={footage.value}
            onChange={handleChange}
            error={!!footage.error}
            helperText={footage.error}
            // required={footage.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Basement"
            name="basement"
            placeholder="Floors that are completely below ground."
            value={basement.value}
            onChange={handleChange}
            error={!!basement.error}
            helperText={basement.error}
            // required={basement.required}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Floors (above ground)"
            name="floors"
            placeholder="Don't include the basement"
            value={floors.value}
            onChange={handleChange}
            error={!!floors.error}
            helperText={floors.error}
            // required={floors.required}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            label="Pool"
            name="pool"
            placeholder="Select"
            value={pool.value}
            onChange={handleChange}
            error={!!pool.error}
            helperText={pool.error}
            // required={pool.required}
          >

            <MenuItem value="NO">
              No
            </MenuItem>
            <MenuItem value="Yes">
              Yes
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Year Built"
            name="year"
            placeholder="Enter year Built"
            value={year.value}
            onChange={handleChange}
            error={!!year.error}
            helperText={year.error}
            // required={year.required}
          />
        </Grid>

      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 45 }} color="success">
          Previous
        </Button>
        <Button
          variant="contained"
          // disabled={isError()}
          color="success"
          onClick={!isError() ? handleNext : () => null}
        >
          Next
        </Button>
      </Box>
    </>
  );
}
