// import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { store } from "./app/store";
import SocketClientProvider from "./contexts/SocketClientProvider";
import AnimatedRoutes from "./app/router/AnimatedRoutes";
import ModalProvider from "./components/Modal/ModalContext";
import Initializer from "./components/Initializer";
import { StepsProvider } from "./Context";

import "./App.css";

const persistor = persistStore(store);

function App() {
  return (
    
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <StepsProvider>
        <Initializer />
        <SocketClientProvider>
          <BrowserRouter>
            <ModalProvider>
              <AnimatedRoutes />
              <ToastContainer />
            </ModalProvider>
          </BrowserRouter>
        </SocketClientProvider>
        </StepsProvider>
      </PersistGate>
    </Provider>
   
  );
}

export default App;
