import { AnimatePresence, motion } from "framer-motion";
// import React from "react";
import { FadeIn } from "../data/variants";
import LoadingPage from "./LoadingPage";

type Props = {
  loading: boolean;
};

const LoadingMessage = ({ loading }: Props) => {
  return (
    <AnimatePresence>
      {loading && (
        <motion.div
          variants={FadeIn}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="absolute w-full h-full bg-black/50"
        >
          <div className="bg-white w-auto flex flex-col items-center justify-center p-4 py-6 rounded-lg absolute z-20 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <LoadingPage />
            <p className="text-sm mt-4">Loading messages</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingMessage;

const PaymentLoading = ({ loading }: Props) => {
  return (
    <AnimatePresence>
      {loading && (
        <motion.div
          variants={FadeIn}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="absolute w-full h-full bg-black/50"
        >
          <div className="bg-white w-auto flex flex-col items-center justify-center p-4 py-6 rounded-lg absolute z-20 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <LoadingPage />
            <p className="text-sm mt-4">Payment Recieved</p>
            <p className="text-sm mt-4">We're Processing your trade</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { PaymentLoading };
