import React, { useCallback, useEffect, useReducer, useState } from "react";
import { MdSend } from "react-icons/md";
import { BsImages } from "react-icons/bs";
import MsgBox, { MsgProps } from "../components/utility/MsgBox";
import { Attachment, Chat, ChatSendValue } from "../ts/interfaces/main";
import { v4 as uuidv4, v4 } from "uuid";
import http from "../utils/http";
import { errorToast } from "../app/toasts";
import { useAppSelector } from "../app/hooks";
import { Contact } from "../components/Admin/UserList";
import { Field, Formik, FormikState } from "formik";
import useAppSocket from "../app/useAppSocket";
import LoadingMessage from "../components/LoadingMessage";
import MarkTradeButton from "../components/MarkTradeButton";
import { findIndex, getFileFromUrl } from "../utils";
import { Retry } from "./ChatSection";
const image_url_prefix = "https://www.flaticon.com/free-icon/done_7799536";

const routes = {
  sendMessage: "/messages/send",
};
interface prevMessages {
  id: number;
  type: "text" | "attachment";
  from_id: number;
  to_id: number;
  body: string;
  attachment: Attachment | string | null;
  seen: number;
  created_at: string;
  updated_at: string;
}
export enum ActionTypes {
  LOAD_MESSAGES = "load_messages",
  SEND_MESSAGE = "send_message",
  SENT = "sent",
  FAILED = "failed",
  RETRY = "retry_message",
  SEND_IMAGE = "send_image",
  ADD_GUEST_MESSAGE = "add_guest_message",
  ADD_GUEST_IMAGE = "add_guest_image",
  ADD_USER_MESSAGE = "add_guest_message",
  ADD_USER_IMAGE = "add_guest_image",
}
interface MessageReducerAction {
  type: ActionTypes;
  payload: string | { uuid: string; message: string } | MsgProps[];
}
export const reducer: (
  message: MsgProps[],
  action: MessageReducerAction
) => MsgProps[] = (
  message: MsgProps[],
  action: MessageReducerAction
): MsgProps[] => {
  switch (action.type) {
    case ActionTypes.LOAD_MESSAGES:
      if (Array.isArray(action.payload)) {
        return action.payload;
      }
      throw new Error("Payload is not an Array");

    case ActionTypes.SEND_MESSAGE:
      if (
        typeof action.payload !== "string" &&
        !Array.isArray(action.payload)
      ) {
        return [
          ...message,
          {
            type: "user",
            message: action.payload.message,
            uuid: action.payload.uuid,
            status: "sending",
          },
        ];
      }
      throw new Error("payload is not an object");

    case ActionTypes.SENT:
      const sent_newList = [...message];
      // action.payload will be a the message ID here
      if (typeof action.payload == "string") {
        const sent_messageId = action.payload;
        if (sent_messageId) {
          const index = findIndex(sent_messageId, sent_newList);
          sent_newList[index] = { ...sent_newList[index], status: "sent" };
          return sent_newList;
        }
      }
      throw new Error("Message Id not found in payload");

    case ActionTypes.RETRY:
      const retry_newList = [...message];
      // action.payload will be a the message ID here
      if (typeof action.payload == "string") {
        const retry_messageId = action.payload;
        if (retry_messageId) {
          const index = findIndex(retry_messageId, retry_newList);
          retry_newList[index] = { ...retry_newList[index], status: "sending" };
          return retry_newList;
        }
      }
      throw new Error("Message Id not found in payload");

    case ActionTypes.FAILED:
      const failed_newList = [...message];
      // action.payload will be a the message ID here
      if (typeof action.payload == "string") {
        const failed_messageId = action.payload;
        if (failed_messageId) {
          const index = findIndex(failed_messageId, failed_newList);
          failed_newList[index] = {
            ...failed_newList[index],
            status: "failed",
          };
          return failed_newList;
        }
      }
      throw new Error("Message Id not found in payload");

    case ActionTypes.SEND_IMAGE:
      if (
        typeof action.payload !== "string" &&
        !Array.isArray(action.payload)
      ) {
        return [
          ...message,
          {
            type: "user",
            message: " ",
            isImageSrc: action.payload.message,
            uuid: action.payload.uuid,
            status: "sending",
          },
        ];
      }
      throw new Error("payload is not an object");

    case ActionTypes.ADD_GUEST_MESSAGE:
      if (typeof action.payload === "string") {
        return [...message, { type: "guest", message: action.payload }];
      }
      throw new Error("payload should be a string");

    case ActionTypes.ADD_GUEST_IMAGE:
      if (typeof action.payload === "string") {
        return [
          ...message,
          {
            type: "guest",
            message: " ",
            isImageSrc: image_url_prefix + action.payload,
          },
        ];
      }
      throw new Error("payload should be a string");

    default:
      return message;
  }
};

const initialState: MsgProps[] = [];

const AdminChatSection = ({ UserContact }: { UserContact: Contact }) => {
  const [messages, dispatch] = useReducer(reducer, initialState);
  const divRef = React.useRef<HTMLDivElement>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { data: admin } = useAppSelector((state) => state.user);
  const [loadingMessages, setLoadingMessages] = useState(false);

  const scrollToBottom = useCallback(() => {
    const currentDiv = divRef.current;
    if (currentDiv) {
      setTimeout(() => {
        currentDiv.scrollTo(0, currentDiv.scrollHeight);
      }, 500);
    }
  }, [divRef]);

  const HandleSend = useCallback(
    (
      { msg }: ChatSendValue,
      resetForm?: (
        nextState?: Partial<FormikState<{ msg: string }>> | undefined
      ) => void,
      retry?: Retry
    ): void => {
      if (admin && UserContact) {
        const messageData = {
          message: msg,
          // room_id: uniqueRoomID,
          room_id: "testing",
          email: admin.email,
          type: "text",
          // User id
          to_id: UserContact.id,
        };

        if (retry) {
          if (retry.retry) {
            if (retry.uuid) {
              dispatch({ type: ActionTypes.RETRY, payload: retry.uuid });

              http
                .post(routes.sendMessage, messageData)
                .then(() => {
                  dispatch({ type: ActionTypes.SENT, payload: retry.uuid });
                })
                .catch((e) => {
                  dispatch({ type: ActionTypes.FAILED, payload: retry.uuid });
                  errorToast("An error Occured");
                  console.error(e);
                });
            }
          }
          return;
        }

        const uniqueID = v4();
        // sending
        dispatch({
          type: ActionTypes.SEND_MESSAGE,
          payload: { uuid: uniqueID, message: msg },
        });

        if (resetForm) resetForm();
        scrollToBottom();
        http
          .post(routes.sendMessage, messageData)
          .then(() => {
            dispatch({ type: ActionTypes.SENT, payload: uniqueID });
          })
          .catch((e) => {
            dispatch({ type: ActionTypes.FAILED, payload: uniqueID });
            errorToast("An error Occured");
            console.error(e);
          });
      }
    },
    [UserContact, admin, scrollToBottom]
  );

  const addNewChat = useCallback(
    (newChat: { message: Chat }) => {
      if (admin) {
        if (parseInt(newChat.message.from_id) === UserContact.id) {
          if (!newChat.message.attachment.type) {
            dispatch({
              type: ActionTypes.ADD_GUEST_MESSAGE,
              payload: newChat.message.message,
            });
            scrollToBottom();
            return;
          }
          // When there is an attachment
          else {
            if (newChat.message.attachment.new_name) {
              dispatch({
                type: ActionTypes.ADD_GUEST_IMAGE,
                payload: newChat.message.attachment.new_name,
              });
            }
            scrollToBottom();
          }
        }
      }
    },
    [admin, UserContact.id, scrollToBottom]
  );

  const selectFile = () => {
    fileInputRef.current?.click();
  };

  const HandleFileSelected = useCallback(
    (imgFile: File | null, retry = false) => {
      if (imgFile) {
        const imgFileUrl = URL.createObjectURL(imgFile);
        const uniqueID = retry ? imgFile.name : v4();
        // sending
        if (retry) {
          dispatch({ type: ActionTypes.RETRY, payload: uniqueID });
        } else {
          dispatch({
            type: ActionTypes.SEND_IMAGE,
            payload: { uuid: uniqueID, message: imgFileUrl },
          });
        }
        scrollToBottom();
        const form = new FormData();
        form.append("type", "attachment");
        form.append("file", imgFile);

        form.append("message", imgFile.name);
        form.append("room_id", "testing");
        form.append("temporaryMsgId", uuidv4());
        if (admin) {
          form.append("to_id", UserContact.id.toString());
        }
        http
          .post("/messages/send", form)
          .then(() => {
            dispatch({ type: ActionTypes.SENT, payload: uniqueID });

            scrollToBottom();
          })
          .catch((err) => {
            console.error(err);
            dispatch({ type: ActionTypes.FAILED, payload: uniqueID });
            errorToast("Your message couldn't be sent. Please try again");
          });
      }
    },
    [admin, UserContact.id, scrollToBottom]
  );

  const handlePayload = useCallback(
    (payload: any) => {
      if (admin && payload.message.to_id === admin.id.toString()) {
        addNewChat(payload);
      }
    },
    [addNewChat, admin]
  );

  const { listen } = useAppSocket();

  useEffect(() => {
    setLoadingMessages(true);
    http
      .post("messages", { user_id: UserContact.id } as any)
      .then((data: any) => {
        const message = data.messages as prevMessages[];

        const loadedMessages: MsgProps[] = message.sort().map((item) => {
          return {
            type:
              UserContact.id.toString() === item.from_id.toString()
                ? "guest"
                : "user",
            message: item.type === "attachment" ? "" : item.body,
            isImageSrc:
              item.type === "attachment" && item.attachment
                ? image_url_prefix +
                  JSON.parse(item.attachment as string).new_name
                : undefined,
          };
        });
        loadedMessages.reverse();
        dispatch({ type: ActionTypes.LOAD_MESSAGES, payload: loadedMessages });
      })
      .catch((err: any) => {
        console.error(err);
        errorToast("Could not get previous messages");
      })
      .finally(() => {
        setLoadingMessages(false);
        scrollToBottom();
      });
  }, [UserContact.id, scrollToBottom]);

  useEffect(() => {
    const cleanUp = listen(handlePayload, `chat-testing`, ".messaging");
    return cleanUp;
  }, [handlePayload, listen]);

  const resendImage = useCallback(
    (uuid: string, imgSrc: string) => {
      dispatch({ type: ActionTypes.RETRY, payload: uuid });
      if (imgSrc) {
        getFileFromUrl(imgSrc, uuid).then((imgFile) => {
          HandleFileSelected(imgFile, true);
        });
      }
    },
    [HandleFileSelected]
  );

  const resendMessage = useCallback(
    (id: string, message: string, isImage: boolean) => {
      if (isImage) {
        // code to resend images, message holds the string for img src
        resendImage(id, message);
      } else {
        HandleSend({ msg: message }, undefined, {
          retry: true,
          uuid: id,
        });
      }
    },
    [HandleSend, resendImage]
  );

  return (
    <div className="relative">
      <div className="flex items-center justify-between w-full py-3 px-6">
        <h1 className="text-sm">Mark trade</h1>
        <MarkTradeButton
          onMarkCancel={() => {
            HandleSend({ msg: "__ff_trade_closed" });
          }}
          onMarkComplete={() => {
            HandleSend({ msg: "__ff_trade_completed" });
          }}
        />
      </div>
      <div
        ref={divRef}
        className="h-[76vh] md:h-[85vh] max-h-[85vh] relative bg-gray-100 py-8 px-4 overflow-y-auto"
        id="chat-scrollbar"
      >
        <div className="max-w-4xl mx-auto space-y-3 grid grid-cols-1">
          <LoadingMessage loading={loadingMessages} />
          {messages.map((item, index) => (
            <MsgBox
              message={item.message}
              isImageSrc={item.isImageSrc}
              type={item.type}
              key={index}
              status={item.status}
              uuid={item.uuid}
              resend={resendMessage}
            />
          ))}
          <div className="w-full h-[38px] bg-transparent" />
        </div>
      </div>
      <Formik
        initialValues={{ msg: "" }}
        onSubmit={(e, { resetForm }) => {
          HandleSend(e, resetForm);
        }}
      >
        {(formik) => (
          <>
            <div className="absolute bottom-0 w-full chatbox flex items-center p-3 justify-between bg-gray-300 shadow-lg">
              <div className="pl-1 flex w-full bg-white rounded-full shadow-lg focus:border-primary">
                <Field
                  type="text"
                  name="msg"
                  className="flex-1 rounded-full w-4/5 p-1 text-l focus:outline-0"
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      formik.submitForm();
                    }
                  }}
                />
                <div
                  onClick={() => selectFile()}
                  className="flex items-center justify-center p-2 rounded-full bg-transparent hover:bg-gray-200"
                >
                  <BsImages className="text-gray-400 text-xl" />
                  <input
                    type="file"
                    className="invisible w-0 h-0"
                    accept="image/*"
                    onChange={(e) =>
                      HandleFileSelected(
                        e.target.files ? e.target.files[0] : null
                      )
                    }
                    ref={fileInputRef}
                  />
                </div>
              </div>
              <div className="flex px-2 gap-x-2">
                <div
                  className="flex items-center justify-center self-end p-3 rounded-full bg-primary"
                  onClick={() => formik.submitForm()}
                >
                  <MdSend className="text-white text-xl" />
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AdminChatSection;
