// import React from 'react'
import GirlSvg from '../svgs/GirlSvg'
import Container from '../utility/Container'
import CTAButton from '../utility/CTAButton'



const TradeWithoutAccount = () => {
    return (
        <section className='w-full bg-white pb-[60px] md:pb-0 md:pt-[60px]'>
            <Container>
                <div className="w-full flex flex-col md:flex-row-reverse items-center justify-center">
                    <GirlSvg className='w-[70%] lg:flex-[0.4] justify-self-end' />
                    <div className="flex lg:flex-[0.6] flex-col items-center md:items-start md:self-start md:pl-4">
                        <h1 className='text-primary font-bold text-center md:text-left text-2xl md:text-[48px]'>No Account Required</h1>
                        <div className='mt-6 py-5 flex flex-col items-start space-y-10 md:space-y-5'>
                            <div className='w-full flex flex-col md:flex-row space-x-2 items-center justify-center md:justify-start'><div className='flex items-center justify-center text-white bg-primary font-bold w-5 h-5 p-5 rounded-full'>1</div><h5 className='font-semibold'>Choose the asset you want to trade</h5></div>
                            <div className='w-full flex flex-col md:flex-row space-x-2 items-center justify-center md:justify-start'><div className='flex items-center justify-center text-white bg-primary font-bold w-5 h-5 p-5 rounded-full'>2</div><h5 className='font-semibold'>Set the amount</h5></div>
                            <div className='w-full flex flex-col md:flex-row space-x-2 items-center justify-center md:justify-start'><div className='flex items-center justify-center text-white bg-primary font-bold w-5 h-5 p-5 rounded-full'>3</div><h5 className='font-semibold'>Get assets in a few minutes</h5></div>
                        </div>
                        <CTAButton className='mt-3' />
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default TradeWithoutAccount