import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/http";
import { LoginUserValues, SignUpUserValues } from "../../ts/interfaces/auth";
import { ApiResponse, ReduxAction } from "../../ts/interfaces/redux";
//export const storagePrefix = "/api/v1/spectrum";
export const storagePrefix = "fFlip-";

interface LoginUserArgs extends ReduxAction {
  values: LoginUserValues;
}
interface SignUpUserArgs extends ReduxAction {
  values: SignUpUserValues;
}

export interface User {
  id: number;
  username: string;
  email: string;
  name: string;
  phone: string | null;
  image: string | null;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
}

interface SignUpUserResponse {
  sentEmail: boolean;
  message: string;
}

interface CreateUserResponse extends ApiResponse {
  data: SignUpUserResponse;
}

interface loginUserResponse extends ApiResponse {
  user: User;
  token: string;
}

export const createUser = createAsyncThunk(
  "/admin/create",
  async (
    { values, onSuccess, onFailure }: SignUpUserArgs,
    { rejectWithValue }
  ) => {
    try {
      const { data }: CreateUserResponse = await axios.post(
        "/api/v1/spectrum/admin/create",
        values
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const saveToken = (token?: string) => {
  localStorage.setItem(storagePrefix + "token", JSON.stringify(token) || "");
};
export const saveUser = (user?: User) => {
  localStorage.setItem(storagePrefix + "user", JSON.stringify(user) || "");
};
export const getUser = () => {
  const localUser: string | null = localStorage.getItem(storagePrefix + "user");
  if (localUser) {
    const user: User = JSON.parse(localUser);
    return user;
  }
};
export const removeToken = () => {
  localStorage.removeItem(storagePrefix + "token");
};

export const loginAdmin = createAsyncThunk(
  "user/login",
  async (
    { values, onSuccess, onFailure }: LoginUserArgs,
    { rejectWithValue }
  ) => {
    try {
      const data: loginUserResponse = await axios.post(
        "/api/v1/spectrum/admin/login",
        values
      );
      console.log("====", data);
      saveToken(data.token);
      if (onSuccess) onSuccess(data.user);
      return data.user;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// export const loginUser = createAsyncThunk(
//   "user/trade",
//   async (
//     { values, onSuccess, onFailure }: LoginUserArgs,
//     { rejectWithValue }
//   ) => {
//     try {
//       const data: loginUserResponse = await axios.post("admin/login", values);
//       saveToken(data.token);
//       if (onSuccess) onSuccess(data.user);
//       return data.user;
//     } catch (error) {
//       if (onFailure) onFailure(error);
//       return rejectWithValue({ error });
//     }
//   }
// );
export const logoutAdmin = createAsyncThunk(
  "user/logout",
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      await axios.get("admin/logout");
      removeToken();
      if (onSuccess) onSuccess(true);
      return;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
