import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { errorToast, successToast } from "../../app/toasts";
import LoadingPage from "../../components/LoadingPage";
import Modal from "../../components/PopModal";
import { logoutAdmin } from "../../features/auth/thunkActions";

type Props = {
  onClose: () => void;
};

const Logout = ({ onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      successToast(`Goodbye`);
      navigate("/login");
    };
    const onFailure = () => {
      setLoading(false);
      errorToast("An error occured while trying to logout, please try again");
    };
    dispatch(logoutAdmin({ onSuccess, onFailure }));
  };
  return (
    <Modal title="Logout" onClose={onClose}>
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        <p>Are you sure you want to log out?</p>
        <div className="flex mt-4 space-x-4 items-center justify-end">
          <button
            type="button"
            onClick={onClose}
            className="text-primary bg-transparent py-2 px-4 rounded "
          >
            Cancel
          </button>
          {loading ? (
            <LoadingPage />
          ) : (
            <button
              type={"button"}
              onClick={handleLogout}
              className="bg-primary text-white py-2 px-4 rounded "
            >
              Yes
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Logout;
