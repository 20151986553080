import { useState } from "react";
import { BiMenu } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Icon1 from "../../utils/more.png";
import Icon2 from "../../utils/data.png";
import Icon3 from "../../utils/wallet.png";
import {  useAppSelector } from "../../app/hooks";

type NavItemsProps = {
  containerClass?: string;
  showHamburger?: boolean;
  onClick?: () => void;
};
const AccountNavItems = ({
  containerClass,
  showHamburger = true,
  onClick,
}: NavItemsProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const user = useAppSelector((state) => state.user);

  const Tab = () => {

    if(user.data ){
     return( <>


      </>)
    }else{
      return(
      <>
        <li>
              <Link
                to="/main"
                className={classNames(
                  "bg-white inline-block text-sm mr-2 font-medium px-3 py-2 md:-mb-4 border-[0px] border-[#55721B] text-black rounded-lg",
                  { "!bg-white !text-primary": !showHamburger }
                )}
              >
                <img className="md:w-6" src={Icon1} alt="Spectrum" />
              </Link>
              <Link
                to="/login"
                className={classNames(
                  "bg-white inline-block text-sm mr-2 font-medium px-3 py-2 md:-mb-4 border-1 border-[#55721B] text-black rounded-lg",
                  { "!bg-white !text-primary": !showHamburger }
                )} 
              >
                <img className="md:w-6" src={Icon2} alt="Spectrum" />
              </Link>
  
              
              <Link
                to="/login"
                className={classNames(
                  "bg-white inline-block text-sm mr-2 font-medium px-3 py-2 md:-mb-4 border-0 border-[#55721B] text-black rounded-lg",
                  { "!bg-white !text-primary": !showHamburger }
                )}
              >
                <img className="md:w-6" src={Icon3} alt="Spectrum" />
              </Link>
              <Link
                to="/signup"
                className={classNames(
                  "bg-primary inline-block text-sm font-medium px-3 py-2 border-[0.5px] border-[#37451a] text-white rounded-lg",
                  { "!bg-white !text-primary": !showHamburger }
                )}
              >
                Account
              </Link>
            </li>
      </>)
    }
  }
 

  const HandleClick = () => {
    setOpenMenu(!openMenu);
    if (onClick) {
      onClick();
    }
  };
  

    return (
      
      <>

 

    <ul className={classNames(containerClass)}>
      
      <Tab/>
      
        </ul>
        {showHamburger &&
          (openMenu ? (
            <GrClose
              className="lg:hidden cursor-pointer text-primary"
              size={25}
              onClick={HandleClick}
              color="#650178"
            />
          ) : (
            <BiMenu
              className="cursor-pointer lg:hidden text-primary"
              onClick={HandleClick}
              size={30}
            />
          ))}
      </>
    );



};

export default AccountNavItems;
