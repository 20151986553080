// import React from "react";
import Layout from "../components/Layout";
import Container from "../components/utility/Container";
import CTAButton from "../components/utility/CTAButton";
import verve from "../assets/img/verve.png";
import paystack from "../assets/img/paystack.png";
import { FaExchangeAlt, FaLock } from "react-icons/fa";
// import Lottie from "react-lottie";
// import * as animationData from "../utils/gift-card.json";
// import * as animation from "../utils/animation.json";
import Hero from "../utils/hero.png";
import Giftcard from "../utils/giftcard.mp4";
import Hands from "../utils/hands.png";
import Steps from "../utils/steps.png";
import CryptoRates from "../components/Home/CryptoRates";
import FAQSection from "./FAQSection";
import { BiSupport } from "react-icons/bi";
import { motion } from "framer-motion";

import img1 from "../utils/bonus.png";
import img2 from "../utils/transfer.png";
import img3 from "../utils/airtime.png";
import img4 from "../utils/airtime.png";
import img5 from "../utils/customer-care.png";
import img6 from "../utils/lincensed.png";
import Onboarding from "../components/Onboarding";

export const textAnimate = {
  offscreen: { y: 100, opacity: 0 },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", bounce: 0.4, duration: 0.5 },
  },
};

const Home2 = () => {
  const slides = [img1, img2, img3, img4, img5, img6];

  return (
    <Layout layout="headerAndFooter">
      {/* Hero */}
      <motion.div
        className="w-full"
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ staggerChildren: 0.5 }}
      >
        <div className="relative flex   md:justify-center md:items-center h-[990px]">
          <div className=" max-w-lg">
            <Onboarding autoSlide={true}>
              {slides.map((s) => (
                <img src={s} alt="Spectrum" />
              ))}
            </Onboarding>
          </div>
        </div>
        <Container className="flex flex-col lg:pt-[60px] relative justify-between items-center lg:flex-row">
          <motion.div
            variants={textAnimate}
            className="flex-1 py-10 space-y-4 pt-[70px] md:py-[100px] lg:py-[40px]"
          >
            <h1 className="text-left text-primary leading-[35px]  font-bold text-[37px] md:text-[55px] md:leading-[53px] md:text-left">
            Need To Sell Your House Fast in Columbus Ohio?.
            </h1>
            <p className="text-sm text-left mt-4 leading-[18px] mb-5 font-normal md:text-sm text-[#3A3A3A]">
              We buy houses in Columbus Ohio in any condition or location.
            </p>
            <div className="flex items-center justify-start">
              <CTAButton className="!my-5 rounded text-lg" />
            </div>
            <div>
              <p className="text-sm md:text-base text-[#3A3A3A]">Secured By</p>
              <div className="flex items-center justify-start mt-0 gap-2">
                <img
                  src={paystack}
                  className="w-1/2 md:w-full max-w-[208.8px]"
                  alt="Spectrum is Secured by paystack"
                />
                <img
                  src={verve}
                  className="w-1/2 md:w-full max-w-[171px]"
                  alt="Spectrum is Secured by verve and mastercard"
                />
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={textAnimate}
            className="w-full mt-6 lg:mt-0 flex justify-center items-start lg:self-start lg:justify-self-end relative lg:-right-[100px] flex-1"
          >
            <img
              src={Hero}
              className=""
              alt="Spectrum is Secured by verve and mastercard"
            />
          </motion.div>
          {/* Rememner to display component(gradient) and set body overflow to hidden */}
          <div
            className="absolute w-[800px] h-[800px] hidden rounded-[301px] -bottom-20 -right-[300px]"
            style={{
              background:
                "radial-gradient(44.85% 44.85% at 50% 50%, rgba(255, 202, 102, 0.4) 18.46%, rgba(255, 175, 0, 0) 100%)",
            }}
          />
        </Container>
      </motion.div>
      <CryptoRates />
      <motion.section
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ staggerChildren: 0.5 }}
        className="py-16"
      >
        <Container className="space-y-4">
          <motion.h1
            variants={textAnimate}
            className="text-[#19233b] font-medium leading-[46px] text-[30px] lg:text-[36px] text-center capitalize"
          >
            You're always safe with Us
          </motion.h1>
          <motion.p variants={textAnimate} className="text-center">
            We buy houses in Columbus Ohio in any condition or location.
          </motion.p>
          <div className="flex pt-5 flex-col items-center justify-center space-y-5 lg:space-y-0 lg:flex-row lg:space-x-5">
            <motion.div
              variants={textAnimate}
              className="w-full bg-white border border-black/10 rounded-xl p-8"
            >
              <div className="flex items-center space-x-4 mb-3">
                <div className="bg-[#9506F3] rounded-full p-4">
                  <FaExchangeAlt color="#fff" size={20} />
                </div>
                <h3 className="text-xl font-semibold">Easy to trade</h3>
              </div>
              <p className="my-10">
                Our procedure has been simplified so users get their homes easily
              </p>
            </motion.div>
            <motion.div
              variants={textAnimate}
              className="w-full bg-white border border-black/10 rounded-xl p-8"
            >
              <div className="flex items-center justify-self-stretch space-x-4 mb-3">
                <div className="bg-[#9506F3] rounded-full p-3">
                  <BiSupport color="#fff" size={28} />
                </div>
                <h3 className="text-xl font-semibold">24/7 Support</h3>
              </div>
              <p className="my-10">
                Get 24/7 support with our friendly customer service agents at
                your service.
              </p>
            </motion.div>

            <motion.div
              variants={textAnimate}
              className="w-full bg-white border border-black/10 rounded-xl p-8"
            >
              <div className="flex items-center space-x-4 mb-3">
                <div className="bg-[#9506F3] rounded-full p-4">
                  <FaLock color="#fff" size={20} />
                </div>
                <h3 className="text-xl font-semibold">Security</h3>
              </div>
              <p className="my-10">
                We have a record of over 325 successfull transaction with 99.9%
                customer satisfaction.
              </p>
            </motion.div>
          </div>
        </Container>
      </motion.section>

      <section>
        {/* Start of iconio theme */}
        <Container>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
            className="flex flex-col-reverse lg:flex-row lg:items-center py-28"
          >
            <div className="flex-1">
              <motion.h2
                variants={textAnimate}
                className="text-[#19233b] font-medium leading-[46px] text-[30px] lg:text-[36px] mb-[40px]"
              >
                Exchange your Assets with trust
              </motion.h2>
              <motion.div
                variants={textAnimate}
                className="text-[#7a839e] text-base"
              >
                <p className="mb-5">
                  Trust is important while deciding securing a home. That is why
                  security has always been and will always be our top priority.
                </p>
              </motion.div>
            </div>
            <div className="flex-1 flex items-center justify-center">
              <img className="w-9/12 mb-6 lg:mb-0" src={Hands} alt="" />
            </div>
          </motion.div>
          {/* end of iconio theme */}

          {/* Start of Digital Payments */}

          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
            className="flex flex-col-reverse lg:flex-row-reverse pb-28"
          >
            <div className="flex-1 flex flex-col items-center justify-center">
              <motion.h2
                variants={textAnimate}
                className="text-[#19233b] font-medium leading-[46px] text-[30px] lg:text-[36px] mb-[40px]"
              >
               We buy houses in Columbus Ohio in any condition or location.
              </motion.h2>
              <motion.div
                variants={textAnimate}
                className="text-[#7a839e] text-base"
              >
                <p className="mb-5">
                  Amazing Properties also allows users to sell homes 
                </p>
              </motion.div>
            </div>
            <div className="flex-1 flex items-center justify-center">
              {/* <img
                className="w-11/12"
                src="https://ecologytheme.com/theme/cryptonio/images/about-2-02.png"
                alt=""
              /> */}
              <video
                controls={false}
                src={Giftcard}
                autoPlay={true}
                muted
                loop
                className="w-10/12 mb-6 lg:mb-0"
              />
            </div>
          </motion.div>
          {/* end of Digital Payment */}

          {/* Start of income multiply */}
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
            className="flex flex-col-reverse lg:flex-row pb-28"
          >
            <div className="flex-1 flex flex-col items-start justify-center">
              <motion.h2
                variants={textAnimate}
                className="text-[#19233b] font-medium leading-[46px] text-[30px] lg:text-[36px] mb-[40px]"
              >
                Get Started in 3 Steps
              </motion.h2>
              <div className="text-[#7a839e] text-base">
                <ul className="flex flex-col gap-y-6">
                  <motion.li
                    variants={textAnimate}
                    className="border-l-4 border-l-primary pl-4"
                  >
                    Tap the <strong>"Trade Now"</strong> button
                  </motion.li>
                  <motion.li
                    variants={textAnimate}
                    className="border-l-4 border-l-primary pl-4"
                  >
                    Select asset you want to trade
                  </motion.li>
                  <motion.li
                    variants={textAnimate}
                    className="border-l-4 border-l-primary pl-4"
                  >
                    Place your trade with Spectrum
                  </motion.li>
                </ul>
                <motion.div variants={textAnimate}>
                  <CTAButton className="!mt-6 rounded text-lg" />
                </motion.div>
              </div>
            </div>
            <div className="flex-1 flex items-center justify-center">
              <img className="w-10/12 mb-6 lg:mb-0" src={Steps} alt="" />
            </div>
          </motion.div>
          {/* end of Digital Payment */}
        </Container>
      </section>
      <FAQSection />
    </Layout>
  );
};

export default Home2;
