import { useState } from "react";
import { errorToast, successToast } from "../../app/toasts";
import http from "../../utils/http";
import * as Yup from "yup";
import Modal from "../PopModal";
import { Form, Formik } from "formik";
import InputField from "../inputs/InputField";
import LoadingPage from "../LoadingPage";
import { useAppDispatch } from "../../app/hooks";
import {
  addAppCurrency,
  CurrencyValue,
  updateAppCurrency,
} from "../../features/asset/slice";

type AddCurrencyProps = {
  currency?: CurrencyValue;
  onClose: () => void;
};

const AddCurrencySchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  symbol: Yup.string().min(2).required(),
  base_rate: Yup.number().min(1).required(),
});

const AddCurrency = ({ onClose, currency }: AddCurrencyProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const addCurrency = (value: {
    name: string;
    symbol: string;
    base_rate: number;
  }) => {
    setLoading(true);
    const onSuccess = (data: any) => {
      setLoading(false);
      if (currency) {
        successToast("Updated successfully");
        dispatch(
          updateAppCurrency({
            id: currency.id,
            created_at: currency.created_at,
            updated_at: currency.updated_at,
            ...value,
          })
        );
      } else {
        successToast(`${value.name} has been added successfully`);
        dispatch(
          addAppCurrency({
            id: data.id,
            name: data.name,
            created_at: data.created_at,
            base_rate: data.base_rate,
            symbol: data.symbol,
            updated_at: data.updated_at,
          })
        );
      }
      onClose();
    };
    const onFailure = () => {
      setLoading(false);
      errorToast("An error occured, please try again");
    };
    if (currency) {
      http
        .post(`/currency/edit/${currency.id}`, value)
        .then(onSuccess)
        .catch(onFailure);
    } else {
      http.post("/currency/create", value).then(onSuccess).catch(onFailure);
    }
  };
  return (
    <Modal
      title={currency ? "Edit Currency" : `Add New Currency`}
      innerDivClassName="mt-6"
      onClose={onClose}
    >
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        <Formik
          validationSchema={AddCurrencySchema}
          initialValues={{
            name: currency?.name || "",
            symbol: currency?.symbol || "",
            base_rate: currency?.base_rate || 100,
          }}
          onSubmit={(e) => {
            addCurrency(e);
          }}
        >
          {() => (
            <Form className="w-full space-y-4">
              <InputField
                name="name"
                placeholder="Name"
                label="Currency Name"
              />
              <InputField name="symbol" placeholder="$" label="Symbol" />
              <InputField
                name="base_rate"
                type={"number"}
                placeholder="100"
                label="Rate"
              />

              {loading ? (
                <LoadingPage />
              ) : (
                <div className="flex space-x-4 items-center justify-end">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-primary bg-transparent py-2 px-4 rounded "
                  >
                    Cancel
                  </button>
                  <button
                    type={"submit"}
                    className="bg-primary text-white py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddCurrency;
