// import React from "react";
// import http from "../../utils/http";
import Container from "../utility/Container";
// import CTAButton from "../utility/CTAButton";
// import { Sparklines, SparklinesLine } from "react-sparklines";
import { motion } from "framer-motion";
import { textAnimate } from "../../pages/Home2";
import img12 from "../../utils/sent.png";
import img16 from "../../utils/withdran.png";

// type cryptoRate = {
//   id: string;
//   symbol: string;
//   name: string;
//   image: string;
//   current_price: number;
//   market_cap: number;
//   market_cap_rank: number;
//   fully_diluted_valuation: number | null;
//   total_volume: number;
//   high_24h: number;
//   low_24h: number;
//   price_change_24h: number;
//   price_change_percentage_24h: number;
//   market_cap_change_24h: number;
//   market_cap_change_percentage_24h: number;
//   circulating_supply: number;
//   total_supply: number;
//   max_supply: number | null;
//   ath: number;
//   ath_change_percentage: number;
//   ath_date: string;
//   atl: number;
//   atl_change_percentage: number;
//   atl_date: string;
//   roi: {
//     times: number;
//     currency: string;
//     percentage: number;
//   } | null;
//   last_updated: string;
//   sparkline_in_7d: {
//     price: number[];
//   };
// };

const CryptoRates = () => {
  // const [cryptoPrices, setCryptoPrices] = useState<cryptoRate[]>([]);
  // const getPrices = () => {
  //   http
  //     .get(
  //       "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=true",
  //       {
  //         withCredentials: false,
  //       }
  //     )
  //     .then((e: any) => {
  //       const data = e as cryptoRate[];
  //       setCryptoPrices(data);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  // };
  // useEffect(() => {
  //   getPrices();
  //   const timer = setTimeout(() => {
  //     getPrices();
  //   }, 1000 * 30);
  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <motion.section
      initial={"occured"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: 0.5 }}
      transition={{ staggerChildren: 0.5 }}
      className=" py-5 md:mx-20 my-10 bg-gray-100"
    >
      <Container>
        <motion.h1
          variants={textAnimate}
          className="text-[#19233b]  mb-5 text-left md:text-center font-medium leading-[33px] text-[20px] lg:text-[36px] capitalize"
        >
          All Transactions History Here
        </motion.h1>
        <hr />

        <div className="flex md:ml-20 md:mr-20 -ml-3 mb-3 justify-between md:m-4">
          <div className="flex flex-1 justify-center items-center">
            <img className="md:w-30 w-12" src={img12} alt="Airtime" />
            <div className="flex flex-1 flex-col ">
              <p className=" text-black text-sm  font-bold">Withdrawal</p>
              <p className=" text-gray-400 text-[11px]  ">25th, 0ct 2023.</p>
            </div>
          </div>

          <div className="flex flex-2 flex-col justify-evenly ml-6  items-end">
            <p className=" text-black text-sm ">-$600.00</p>
            <p className=" text-green-500 text-[10px]">Successful</p>
          </div>
        </div>
        <hr />

        <div className="flex md:ml-20 md:mr-20 -ml-3 mb-3 justify-between md:m-4">
          <div className="flex flex-1 justify-center items-center">
            <img className="md:w-30 w-12" src={img12} alt="Airtime" />
            <div className="flex flex-1 flex-col ">
              <p className=" text-black text-sm  font-bold">Withdrawal</p>
              <p className=" text-gray-400 text-[11px]  ">25th, 0ct 2023.</p>
            </div>
          </div>

          <div className="flex flex-2 flex-col justify-evenly ml-6  items-end">
            <p className=" text-black text-sm ">-$600.00</p>
            <p className=" text-blue-500 text-[10px]">Pending</p>
          </div>
        </div>
        <hr />

        <div className="flex md:ml-20 md:mr-20 -ml-3 mb-3 justify-between md:m-4">
          <div className="flex flex-1 justify-center items-center">
            <img className="md:w-30 w-12" src={img16} alt="Airtime" />
            <div className="flex flex-1 flex-col ">
              <p className=" text-black text-sm  font-bold">Deposit</p>
              <p className=" text-gray-400 text-[11px]  ">25th, 0ct 2023.</p>
            </div>
          </div>

          <div className="flex flex-2 flex-col justify-evenly ml-6  items-end">
            <p className=" text-black text-sm ">+$400.00</p>
            <p className=" text-green-500 text-[10px]">Successful</p>
          </div>
        </div>

        <hr />

        <div className="flex md:ml-20 md:mr-20 -ml-3 mb-3 justify-between md:m-4">
          <div className="flex flex-1 justify-center items-center">
            <img className="md:w-30 w-12" src={img12} alt="Airtime" />
            <div className="flex flex-1 flex-col ">
              <p className=" text-black text-sm  font-bold">Withdrawal</p>
              <p className=" text-gray-400 text-[11px]  ">25th, 0ct 2023.</p>
            </div>
          </div>

          <div className="flex flex-2 flex-col justify-evenly ml-6  items-end">
            <p className=" text-black text-sm ">-$200.00</p>
            <p className=" text-green-500 text-[10px]">Successful</p>
          </div>
        </div>

        <hr />

        <div className="flex md:ml-20 md:mr-20 -ml-3 mb-3 justify-between md:m-4">
          <div className="flex flex-1 justify-center items-center">
            <img className="md:w-30 w-12" src={img16} alt="Airtime" />

            <div className="flex flex-1 flex-col ">
              <p className=" text-black text-sm  font-bold">Deposit</p>
              <p className=" text-gray-400 text-[11px]  ">25th, 0ct 2023.</p>
            </div>
          </div>

          <div className="flex flex-2 flex-col justify-evenly ml-6 items-end ">
            <p className=" text-black text-sm ">+$500.00</p>
            <p className=" text-green-500 text-[10px]">Successful</p>
          </div>
        </div>
      </Container>
    </motion.section>
  );
};

export default CryptoRates;
