import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { errorToast, successToast } from "../../app/toasts";
import { NetworkReturnValue } from "../../features/asset/thunkActions";
import LoadingPage from "../LoadingPage";
import cls from "classnames";
import { MdEdit, MdDelete } from "react-icons/md";
import AddNetwork from "./AddNetwork";
import { AnimatePresence } from "framer-motion";
import Modal from "../PopModal";
import { modalAction } from "../../ts/interfaces/main";
import http from "../../utils/http";
import { removeAppNetwork } from "../../features/asset/slice";

const Networks = () => {
  const [openAddNetwork, setOpenAddNetwork] = useState<boolean>(false);
  const [network, setNetwork] = useState<NetworkReturnValue | undefined>(
    undefined
  );
  const { loading, networkData } = useAppSelector((state) => state.networks);

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [networkToDelete, setNetworkToDelete] = useState<
    NetworkReturnValue | undefined
  >(undefined);
  return (
    <>
      <AnimatePresence>
        {openAddNetwork && (
          <AddNetwork
            network={network}
            onClose={() => {
              setNetwork(undefined);
              setOpenAddNetwork(false);
              // add network to store
            }}
          />
        )}
        {showDelete && networkToDelete && (
          <DeleteNetwork
            network={networkToDelete}
            onClose={() => {
              setNetworkToDelete(undefined);
              setShowDelete(false);
              // remove network from store
            }}
          />
        )}
      </AnimatePresence>
      <div className="flex flex-wrap space-x-4 items-center">
        <h2 className="font-bold text-[32px]">Networks</h2>
        <button
          onClick={() => setOpenAddNetwork(true)}
          className="bg-[#b712d8] hover:bg-primary my-2 text-white py-2 px-4 rounded text-sm md:text-base"
        >
          Add Networks
        </button>
      </div>
      <div
        className={cls(
          "flex gap-3 my-3 items-center flex-wrap",
          { "gap-x-10": networkData.length < 3 },
          { "justify-between": networkData.length >= 3 }
        )}
      >
        {loading ? (
          <LoadingPage />
        ) : (
          networkData &&
          networkData.map((item) => (
            <div
              key={item.id.toString()}
              className="px-5 py-3 w-full md:w-2/5 flex items-center justify-between border border-gray-200"
            >
              <div className="flex items-baseline gap-2">
                <p className="text-sm font-semibold">{item.name}</p>
                <p className="text-xs font-semibold text-gray-300">
                  {item.short_name}
                </p>
              </div>
              <div className="flex gap-2">
                <MdEdit
                  onClick={() => {
                    setNetwork(item);
                    setOpenAddNetwork(true);
                  }}
                  className="cursor-pointer text-2xl text-black hover:scale-105 ease-in duration-100"
                />
                <MdDelete
                  onClick={() => {
                    setNetworkToDelete(item);
                    setShowDelete(true);
                  }}
                  className="cursor-pointer text-2xl text-black hover:scale-105 ease-in duration-100"
                />
              </div>
            </div>
          ))
        )}
        {!loading && networkData.length === 0 && (
          <p className="text-gray-600 mt-3">
            No Network available, Add a new Network
          </p>
        )}
      </div>
    </>
  );
};

export default Networks;

interface deleteNetworkProps extends modalAction {
  network: NetworkReturnValue;
}
const DeleteNetwork = ({ network, onClose }: deleteNetworkProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const networkDelete = () => {
    setLoading(true);

    const onSuccess = () => {
      setLoading(false);
      successToast(`${network.name} Deleted"`);
      dispatch(removeAppNetwork(network));
      onClose();
    };

    const onFailure = () => {
      setLoading(false);
      errorToast("An error occured, please try again");
    };

    http.get(`/network/delete/${network.id}`).then(onSuccess).catch(onFailure);
  };
  return (
    <Modal
      title={network ? "Edit Network" : `Add New Network`}
      innerDivClassName="mt-6"
      onClose={onClose}
    >
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        <p className="mb-4">Are you sure you want to delete {network.name}?</p>
        {loading ? (
          <LoadingPage />
        ) : (
          <div className="flex space-x-4 items-center justify-end">
            <button
              type="button"
              onClick={onClose}
              className="text-primary bg-transparent py-2 px-4 rounded "
            >
              Cancel
            </button>
            <button
              type={"submit"}
              onClick={networkDelete}
              className="bg-primary text-white py-2 px-4 rounded"
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
