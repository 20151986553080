import { useState } from "react";
import { NavLinks } from "../../data";
import { BiMenu } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import classNames from "classnames";

type NavItemsProps = {
  containerClass?: string;
  showHamburger?: boolean;
  onClick?: () => void;
};
const NavItems = ({
  containerClass,
  showHamburger = true,
  onClick,
}: NavItemsProps) => {
  const [openMenu, setOpenMenu] = useState(false);

  const HandleClick = () => {
    setOpenMenu(!openMenu);
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <ul className={classNames(containerClass)}>
        {NavLinks.map((item, index) => (
          <li key={index} >
            <Link
              className={classNames("text-[17px] font-medium", {
                "!text-left": !showHamburger,
              })}
              to={item.link}
              // to={{ pathname: {item.link}, hash: {item.hash} }}
              // data-target={item.hash}

              style={{ paddingRight: '20px', marginRight: '80px' }}
            >
              {item.name}
            </Link>

          </li>
        ))}

        <li>
          <a href="/#faq" className={classNames("text-[17px] font-medium", {
            "!text-left": !showHamburger,
          })} style={{ marginRight: '55px' }}>
            FAQ
          </a>
         

        </li>
        <li>
        
          <a href="/#contactus" className={classNames("text-[17px] font-medium", {
            "!text-left": !showHamburger,
          })} style={{ marginRight: '45px' }}>
            Contact Us
          </a>

        </li>

        <li style={{ marginRight: '45px' }}>

          <Link
            to="/sell"
            className={classNames(
              "bg-primary inline-block text-lg font-medium px-3 py-2 border-[1px] border-[#00B776] text-white rounded-lg",
              { "!bg-white !text-primary": !showHamburger }
            )}

          >
            Sell Property
          </Link>
        </li>
      </ul>
      {showHamburger &&
        (openMenu ? (
          <GrClose
            className="lg:hidden cursor-pointer text-primary"
            size={25}
            onClick={HandleClick}
            color="#650178"
          />
        ) : (
          <BiMenu
            className="cursor-pointer lg:hidden text-primary"
            onClick={HandleClick}
            size={30}
          />
        ))}
    </>
  );
};

export default NavItems;
