import { Form, Formik } from "formik";
import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { errorToast, successToast } from "../../app/toasts";
import { updateAppCrypto } from "../../features/asset/slice";
import { CryptoCurrency } from "../../features/asset/thunkActions";
import http from "../../utils/http";
import CurrencyInputField from "../inputs/CurrencyInputField";
import LoadingPage from "../LoadingPage";
import Modal from "../PopModal";

type Props = {
  crypto: CryptoCurrency;
  onClose: () => void;
};

interface editCrpyto {
  buy_rate: number;
  sell_rate: number;
}

const EditCrypto = ({ crypto, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const handleEditSubmit = (e: editCrpyto) => {
    setLoading(true);
    const onSuccess = () => {
      dispatch(
        updateAppCrypto({
          ...crypto,
          buy_rate: e.buy_rate,
          sell_rate: e.sell_rate,
        })
      );
      successToast("Update Successful");
      onClose();
    };

    const onFailure = (err: any) => {
      console.error(err);
      errorToast("An error occured");
    };
    http
      .post(`/crypto/edit/${crypto.id}`, e)
      .then(onSuccess)
      .catch(onFailure)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal title={`Edit ${crypto.name}`} onClose={onClose}>
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        <Formik
          initialValues={{
            buy_rate: crypto.buy_rate || 0,
            sell_rate: crypto.sell_rate || 0,
          }}
          onSubmit={(e) => {
            handleEditSubmit(e);
          }}
        >
          {() => (
            <Form className="w-full space-y-4">
              <CurrencyInputField
                name="buy_rate"
                placeholder="Buy Price"
                label="Buy Price"
              />
              <CurrencyInputField
                name="sell_rate"
                placeholder="Sell Price"
                label="Sell Price"
              />
              {loading ? (
                <LoadingPage />
              ) : (
                <div className="flex space-x-4 items-center justify-end">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-primary bg-transparent py-2 px-4 rounded "
                  >
                    Cancel
                  </button>
                  <button
                    type={"submit"}
                    className="bg-primary text-white py-2 px-4 rounded "
                  >
                    Submit
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditCrypto;
