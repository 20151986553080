// import React from "react";
import { Link } from "react-router-dom";
import Container from "../utility/Container";
import NavItems from "./NavItems";
import Logo from "../../assets/img/Logo.png";

type NavigationProps = {
  onClick: () => void;
};

const NavBar = ({ onClick }: NavigationProps) => {
  return (
    <header className="bg-gray-100 w-full flex static items-center justify-center pt-2 pb-2 md:pt-2 md:pb-1">
      <Container className="w-10/12 lg:!w-[95%]">
        <div className="w-full flex items-center justify-between">
          <Link to="/" className="flex gap-x-5 items-center justify-center" style={{marginLeft:'68px'}}>
            <img
              src={Logo}
              className="w-[100px] md:w-[140px]"
              alt="Amazing Properties logo"
            />
          </Link>
          <nav>
            <NavItems
              containerClass="hidden lg:flex items-center justify-center gap-x-7"
              onClick={onClick}
            />
          </nav>
        </div>
      </Container>
    </header>
  );
};

export default NavBar;
