import classNames from "classnames";
import { Form, Formik, FormikErrors } from "formik";
import { Fragment, useEffect } from "react";
import InputField from "../components/inputs/InputField";
import PasswordInputField from "../components/inputs/PasswordInputField";
import Container from "../components/utility/Container";
// import GoogleButton from "../components/utility/GoogleButton";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AnimVariants from "../data/variants";
import { loginAdmin, User } from "../features/auth/thunkActions";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { errorToast, successToast } from "../app/toasts";
import { LoginUserValues } from "../ts/interfaces/auth";
import LoadingPage from "../components/LoadingPage";
import Layout from "../components/Layout";
import * as Yup from "yup";
import { CustomAxiosError } from "../ts/interfaces/main";
import Logo from "../utils/logo.svg";

//
const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { isLoading } = useAppSelector((state) => state.user);
  const currentTrade = useAppSelector((state) => state.currentTrade);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogin = (
    values: LoginUserValues,
    setErrors: (errors: FormikErrors<LoginUserValues>) => void
  ) => {
    // Add Crypto
    const onSuccess = (user: User) => {
      successToast(`Welcome ${user.name || "user"}`);
      if (currentTrade) {
        navigate(`/admin/trade/${currentTrade.status}`);
      } else {
        navigate("/main");
      }
    };
    const onFailure = (err: CustomAxiosError) => {
      setErrors(err.data.errors);
      if (err.data.message === "These credentials do not match our records.") {
        errorToast("Email or password incorrect");
      } else {
        errorToast("Invalid Password or an error occured, please try again");
        // navigate("/main");
      }
      if (err.data.message === "Password mismatch") {
        errorToast(err.data.message);
      }
    };
    dispatch(loginAdmin({ values, onSuccess, onFailure }));
  };

  return (
    <Layout layout="none">
      <motion.div
        variants={AnimVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <svg
          className="hidden lg:blockz-0 absolute top-0 left-0"
          width="672"
          height="475"
          viewBox="0 0 672 475"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.05"
            d="M-83.9121 425.347C-27.9433 395.623 -6.7814 356.43 -20.4263 307.771C-40.8936 234.781 -18.503 188.186 43.1875 184.393C104.878 180.601 163.096 233.52 178.681 296.864C194.266 360.208 280.957 361.96 315.34 349.41C349.724 336.86 369.462 311.555 368.073 283.976C365.001 222.984 253.327 174.387 255.94 118.156C258.553 61.925 350.376 41.2739 407.979 91.9161C410.817 94.4111 413.708 96.7593 416.644 98.9654C475.674 143.318 553.026 130.213 588.396 97.3155C649.731 40.2688 621.761 -20.6983 523.35 -62.0658C375.196 -127.262 296.342 -198.724 286.789 -276.452"
            stroke="url(#paint0_linear_42_293)"
            strokeWidth="100"
            strokeLinecap="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_42_293"
              x1="-83.9121"
              y1="425.347"
              x2="372.999"
              y2="-401.176"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0869954" stopColor="#DB2E7D" />
              <stop offset="0.364583" stopColor="#7A0490" />
              <stop offset="0.828125" stopColor="#FFAF00" />
            </linearGradient>
          </defs>
        </svg>

        <div className="flex z-10 relative">
          <div className="h-screen flex flex-col w-full lg:w-[55%]">
            <div>
              <Container className="w-10/12 lg:w-[93%] py-3">
                <Link
                  to="/"
                  className="flex max-w-fit gap-3 items-center justify-start"
                >
                  <img
                    src={Logo}
                    className="w-[205px] md:w-auto"
                    alt="Spectrum logo"
                  />
                </Link>
              </Container>
            </div>
            <div className="flex-1 pt-14 lg:pt-0 lg:flex items-center justify-center">
              <Container className="flex items-center justify-center">
                <div className="flex-1 space-y-3">
                  <Formik
                    validationSchema={LoginSchema}
                    initialValues={{ email: "", password: "" }}
                    onSubmit={(e, { setErrors }) => handleLogin(e, setErrors)}
                  >
                    {() => (
                      <Form className="space-y-4 max-w-[400px] mx-auto">
                        <InputField
                          name="email"
                          label="Email Address"
                          placeholder="yourname@gmail.com"
                        />
                        <PasswordInputField
                          placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                          name="password"
                          label="Password"
                        />
                        <div className="space-y-3 mb-3">
                          <p
                            className={classNames(
                              "text-[#CCCCCC] text-xs text-left"
                            )}
                          >
                            Password must contain a minimum of 8 characters
                          </p>
                          <p
                            className={classNames(
                              "text-[#CCCCCC] text-xs text-left"
                            )}
                          >
                            Password must contain at least one symbol e.g. @, !
                          </p>
                        </div>
                        <div className="flex items-center justify-around">
                          <button className="bg-transparent border-0 font-medium text-xs inline-flex items-center justify-center">
                            <h4>Forgot Password</h4>
                          </button>
                        </div>
                        <Fragment>
                          {isLoading ? (
                            <LoadingPage />
                          ) : (
                            <button
                              type="submit"
                              className="py-2 bg-primary text-white rounded-3xl w-full font-bold"
                            >
                              Sign In
                            </button>
                          )}
                        </Fragment>

                        <p className="text-xs text-center font-medium text-[#595959]">
                          Don't have an account?{" "}
                          <Link
                            to="/signup"
                            className="text-primary cursor-pointer"
                          >
                            Sign Up
                          </Link>
                        </p>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Container>
            </div>
          </div>
          <div className="hidden lg:flex flex-col w-[45%] h-screen bg-gradient-to-br from-[#845824] to-[#467B19]">
            <div></div>
            <div className="flex-1 flex items-center justify-center">
              <Container className="lg:w-[11/12]">
                <h1 className="text-[#E4E4E4] font-bold text-[4rem]">
                  Welcome back, Let’s get you started
                </h1>
              </Container>
            </div>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
};

export default Login;
