import { useEffect, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { errorToast } from "../../app/toasts";
import { addCurrentTrade, clearCurrentTrade } from "../../features/contacts/slice";
import http from "../../utils/http";
import InfoPane from "./InfoPane";

const TradeStatus = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const currentTrade = useAppSelector((state) => state.currentTrade);
  const { data } = useAppSelector((state) => state.user);
  const { status } = useParams<{ status: string }>();
  const token = searchParams.get("token");

  const HandleTrade = useCallback(() => {
    if (currentTrade && data) {
      http
        .get(`/admin/trade/${currentTrade.status}/${currentTrade.token}`)
        .then(() => {
          if (currentTrade.status === "approve") {
            Swal.fire(
              "Trade Approved",
              "You can view transaction details in transaction page or your mail",
              "success"
            ).then((res) => {
              if (res.isConfirmed || res.dismiss) {
                dispatch(clearCurrentTrade())
              }
            });
          }
          if (currentTrade.status === "reject") {
            Swal.fire(
                "Trade Declined!",
                "You can view transaction details in transaction page or your mail",
                "error"
              ).then((res) => {
                if (res.isConfirmed || res.dismiss) {
                  dispatch(clearCurrentTrade())
                }})
          }
        }).catch((err:any)=>{
            console.error(err)
            errorToast("An error occured while responding to trade")
        });
    }
  }, [currentTrade, data, dispatch]);

  useEffect(() => {
    if (!currentTrade && token) {
      dispatch(
        addCurrentTrade({
          status: status === "approve" ? "approve" : "reject",
          token: token,
        })
      );
      console.log("saved");
    }
    // Check if theres a current trade and user is logged in
    if (currentTrade && data) {
        HandleTrade()
        // dispatch(clearCurrentTrade());
      
    }
  }, [currentTrade, dispatch, status, token, data,HandleTrade]);

  return (
    <InfoPane>
      <div>TradeStatus</div>
    </InfoPane>
  );
};

export default TradeStatus;
