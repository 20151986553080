// import React from 'react'
import coinBg from '../../assets/img/coinBg.png'

import Container from '../utility/Container'

const AcrossCountries = () => {
    return (
        <section className='w-full py-[100px] md:py-[100px] relative bg-no-repeat bg-cover' style={{ backgroundImage: `url(${coinBg})` }}>
            <div className='absolute w-full top-0 h-full bg-primary mix-blend-difference z-0'/>
            <Container className=''>
                <div className="flex flex-col items-center">
                    <h1 className='z-50 text-white text-center text-[24px] lg:w-11/12 md:text-[32px]'>Trade cryptocurrencies to naira and other african currencies within minutes</h1>
                    
                </div>
            </Container>
        </section>
    )
}

export default AcrossCountries