import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import classNames from "classnames";
type iconSelectBoxProps = {
  title: string;
  desc: string;
  icon: ReactNode;
  to: string;
  className?: string;
  onClick?: () => void;
};

const IconSelectBox = ({
  title,
  desc,
  icon,
  to,
  className,
  onClick,
}: iconSelectBoxProps) => {
  return (
    <Link
      to={to}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <div
        className={classNames(
          "w-full max-w-[265px] px-[18px] py-[21px] bg-[#CCCCCC]/25",
          className
        )}
      >
        <h1 className="text-[20px] font-[500]">{title}</h1>
        <p className="text-[#464646] text-[15px] mb-3">{desc}</p>
        <div className="flex items-center justify-between">
          {icon}
          <MdArrowForwardIos className="justify-self-center text-4xl text-[#979797]" />
        </div>
      </div>
    </Link>
  );
};

export default IconSelectBox;
