import { useState, useEffect } from "react";
import http from "../../utils/http";
import Container from "../utility/Container";
import CTAButton from "../utility/CTAButton";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { motion } from "framer-motion";
import { textAnimate } from "../../pages/Home2";

type cryptoRate = {
  id: string;
  symbol: string;
  name: string;
  image: string;
  current_price: number;
  market_cap: number;
  market_cap_rank: number;
  fully_diluted_valuation: number | null;
  total_volume: number;
  high_24h: number;
  low_24h: number;
  price_change_24h: number;
  price_change_percentage_24h: number;
  market_cap_change_24h: number;
  market_cap_change_percentage_24h: number;
  circulating_supply: number;
  total_supply: number;
  max_supply: number | null;
  ath: number;
  ath_change_percentage: number;
  ath_date: string;
  atl: number;
  atl_change_percentage: number;
  atl_date: string;
  roi: {
    times: number;
    currency: string;
    percentage: number;
  } | null;
  last_updated: string;
  sparkline_in_7d: {
    price: number[];
  };
};

const CryptoRates = () => {
  const [cryptoPrices, setCryptoPrices] = useState<cryptoRate[]>([]);
  const getPrices = () => {
    http
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=true",
        {
          withCredentials: false,
        }
      )
      .then((e: any) => {
        const data = e as cryptoRate[];
        setCryptoPrices(data);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  useEffect(() => {
    getPrices();
    const timer = setTimeout(() => {
      getPrices();
    }, 1000 * 30);
    return () => clearTimeout(timer);
  }, []);
  return (
    <motion.section
      initial={"occured"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: 0.5 }}
      transition={{ staggerChildren: 0.5 }}
      className="rounded-md py-5 mx-3 my-10"
    >
      <Container>
        <motion.h4
          variants={textAnimate}
          className="text-left text-sm md:text-center mb-1 text-gray-700 capitalize"
        >
          Never sell for less
        </motion.h4>
        <motion.h1
          variants={textAnimate}
          className="text-[#19233b] text-left md:text-center font-medium leading-[46px] text-[30px] lg:text-[36px] capitalize"
        >
          Real time Crypto Prices
        </motion.h1>
        <motion.div
          variants={textAnimate}
          className="flex flex-col items-center justify-center lg:flex-row gap-x-4 lg:hidden"
        >
          {typeof cryptoPrices !== "undefined"
            ? cryptoPrices.map((i, k) => {
                if (k > 4) {
                  return null;
                }
                return (
                  <div
                    className="bg-white w-full max-w-[365px] flex border border-black/10 rounded-xl shadow-lg p-4 py-6 my-4"
                    key={k}
                  >
                    <div className="">
                      <img
                        src={i.image}
                        alt=""
                        className="max-w-[40px] max-h-[40px]"
                      />
                    </div>
                    <div className="gap-2 px-2 pb-3 pl-4">
                      <h4 className="text-xl font-semibold">{i.name}</h4>
                      <p>{i.symbol.toUpperCase()}</p>
                      <p className="text-[#7A839E]">
                        ${i.current_price.toLocaleString()}
                      </p>
                    </div>
                    <div className="h-full w-full items-end justify-end">
                      <Sparklines data={i.sparkline_in_7d.price}>
                        <SparklinesLine color="#6443E0" />
                      </Sparklines>
                    </div>
                  </div>
                );
              })
            : null}
        </motion.div>
        <motion.div
          variants={textAnimate}
          className="hidden lg:flex items-center justify-center"
        >
          <table className="hidden min-w-full md:table">
            <thead className="bg-white border-b">
              <tr>
                <th
                  scope="col"
                  className="text-lg font-semibold text-black px-6 py-4 text-left"
                >
                  Name
                </th>

                <th
                  scope="col"
                  className="hidden md:table-cell text-lg font-semibold text-black px-6 py-4 text-left"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="hidden md:table-cell text-lg font-semibold text-black px-6 py-4 text-left"
                >
                  24h change
                </th>
                <th
                  scope="col"
                  className="hidden md:table-cell text-lg font-semibold text-black px-6 py-4 text-left"
                >
                  Chart
                </th>
                <th
                  scope="col"
                  className="hidden md:table-cell text-lg font-semibold text-black px-6 py-4 text-left"
                >
                  Trade
                </th>
              </tr>
            </thead>
            <tbody>
              {typeof cryptoPrices !== "undefined"
                ? cryptoPrices.map((item, index) => (
                    <tr className="" key={index}>
                      <td className="py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="flex gap-x-2 items-center justify-start">
                          <img
                            src={item.image}
                            className="max-w-[26px] max-h-[26px]"
                            alt="bitcoin symbol"
                          />
                          <div className="">
                            <h4 className="text-[13px] font-semibold">
                              {item.name}
                            </h4>
                            <h5 className="text-[10px] font-semibold">
                              {item.symbol.toUpperCase()}
                            </h5>
                          </div>
                        </div>
                      </td>

                      <td className="hidden md:table-cell font-semibold px-6 py-4 whitespace-nowrap">
                        $ {item.current_price.toLocaleString()}
                      </td>
                      <td className="hidden md:table-cell font-semibold px-6 py-4 whitespace-nowrap">
                        {item.market_cap_change_24h < 0 ? (
                          <span className="text-red-700">
                            {item.market_cap_change_percentage_24h.toFixed(3)}%
                          </span>
                        ) : (
                          <span className="text-green-700">
                            +{item.market_cap_change_percentage_24h.toFixed(3)}%
                          </span>
                        )}
                      </td>
                      <td className="hidden md:table-cell text-[#0FA958] font-semibold px-6 py-4 whitespace-nowrap">
                        {/* <img
                      src="https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg"
                      alt=""
                      className="object-fit"
                    /> */}

                        <Sparklines data={item.sparkline_in_7d.price}>
                          <SparklinesLine
                            color="#6443E0"
                            style={{ borderWidth: 2 }}
                          />
                        </Sparklines>
                      </td>
                      <td className="hidden md:block text-gray-900 font-light py-4 whitespace-nowrap">
                        <CTAButton className="rounded-full" />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </motion.div>
      </Container>
    </motion.section>
  );
};

export default CryptoRates;
