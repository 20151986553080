import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { errorToast } from "../../app/toasts";
import AdminLayout from "./Layout";
const AdminCheck = () => {
  const navigate = useNavigate();
  const { data } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (!data) {
      errorToast("Session expired, Login again!");
      navigate("/login");
    }
  }, [data, navigate]);

  if (data) {
    return <AdminLayout />;
    
  }

  return <div></div>;
};

export default AdminCheck;
