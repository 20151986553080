import { useEffect, useState } from "react";
import IconSelectBox from "../components/IconSelectBox";
import CardsSvg from "../components/svgs/CardsSvg";
import WalletSvg from "../components/svgs/WalletSvg";
import Container from "../components/utility/Container";
import AnimVariants from "../data/variants";
import { motion } from "framer-motion";
import Layout from "../components/Layout";
import WhatsAppModal from "../components/utility/WhatsAppModal";

const ChooseCurrency = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [type, setType] = useState<"buy" | "sell" | "giftcard" | null>(null);
  return (
    <Layout>
      <motion.div
        variants={AnimVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="py-[60px]"
      >
        <Container>
          {type && (
            <WhatsAppModal
              type={type}
              onClose={() => {
                setType(null);
              }}
            />
          )}
          <h1 className="text-center text-[30px] md:text-[48px] mb-3 text-primary font-bold capitalize">
            What do you want to do?
          </h1>
          <div className="py-[60px] flex flex-col items-center justify-center gap-5 lg:flex-row">
            <IconSelectBox
              to="#"
              onClick={() => {
                setType("giftcard");
              }}
              title="Sell Giftcard"
              desc="sell your giftcard at the best rates in the market"
              icon={<CardsSvg />}
            />
            <IconSelectBox
              to="#"
              title="Buy Crypto"
              onClick={() => {
                setType("buy");
              }}
              desc="Buy crypto at the best rate in the market"
              icon={<CardsSvg />}
            />
            <IconSelectBox
              to="#"
              title="Sell Crypto"
              onClick={() => {
                setType("sell");
              }}
              desc="Sell crypto at the best rate in the market"
              icon={<WalletSvg />}
            />
          </div>
        </Container>
      </motion.div>
    </Layout>
  );
};

export default ChooseCurrency;
