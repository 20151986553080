// import React from 'react'


const WalletSvg = ({ className }: { className?: string }) => {
    return (
        <svg width="73" height="47" className={className} viewBox="0 0 73 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M59.4236 46H5.21343C3.78644 46 2.62964 44.8432 2.62964 43.4162V11.5122C2.62964 10.0852 3.78644 8.9286 5.21343 8.9286H59.4236C60.8506 8.9286 62.0072 10.0852 62.0072 11.5122V43.4162C62.0072 44.8432 60.8506 46 59.4236 46Z" fill="#A562BA" />
            <path d="M37.9212 16.1575C34.8492 16.9859 31.6869 15.1671 30.8584 12.0949C30.0299 9.02291 31.8491 5.86089 34.9211 5.03245C37.9931 4.20401 41.155 6.02276 41.9834 9.09476C42.8119 12.1669 40.9932 15.329 37.9212 16.1575Z" fill="#F8E71C" />
            <path d="M39.0647 13.625C35.9927 14.4535 32.8305 12.6346 32.0021 9.56239C31.1736 6.49039 32.9925 3.32842 36.0645 2.49998C39.1365 1.67153 42.2983 3.49034 43.1267 6.56234C43.9552 9.63452 42.1367 12.7966 39.0647 13.625Z" stroke="#373746" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.1079 26.2704C22.1411 27.42 18.8041 25.9466 17.6545 22.9797C16.505 20.0129 17.9781 16.6758 20.945 15.5262C23.9118 14.3767 27.2488 15.8501 28.3984 18.8169C29.5479 21.7838 28.0747 25.1209 25.1079 26.2704Z" fill="#F8E71C" />
            <path d="M25.9766 23.6311C23.0097 24.7806 19.6727 23.3073 18.5232 20.3404C17.3737 17.3736 18.8468 14.0365 21.8136 12.8869C24.7805 11.7374 28.1175 13.2108 29.267 16.1776C30.4165 19.1445 28.9434 22.4816 25.9766 23.6311Z" stroke="#373746" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M64.533 45.2602H5.31702C3.48506 45.2602 2 43.7752 2 41.9434V7.46512C2 5.63316 3.48506 4.1481 5.31702 4.1481H64.533C66.365 4.1481 67.8499 5.63316 67.8499 7.46512V41.9434C67.8499 43.7752 66.365 45.2602 64.533 45.2602Z" stroke="#373746" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.63281 39.8072H20.176" stroke="#373746" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.4341 39.8072H41.9773" stroke="#373746" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M50.3469 39.8072H61.8902" stroke="#373746" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M69.2078 31.3875H54.5722C53.4345 31.3875 52.5125 30.4656 52.5125 29.3281V23.4222C52.5125 22.2848 53.4345 21.3627 54.5722 21.3627H69.2078C70.3452 21.3627 71.2671 22.2848 71.2671 23.4222V29.3281C71.2671 30.4656 70.3452 31.3875 69.2078 31.3875Z" fill="white" />
            <path d="M69.2078 31.3875H54.5722C53.4345 31.3875 52.5125 30.4656 52.5125 29.3281V23.4222C52.5125 22.2848 53.4345 21.3627 54.5722 21.3627H69.2078C70.3452 21.3627 71.2671 22.2848 71.2671 23.4222V29.3281C71.2671 30.4656 70.3452 31.3875 69.2078 31.3875Z" stroke="#373746" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default WalletSvg