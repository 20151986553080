// import React from "react";
import { BsCheckLg } from "react-icons/bs";
import AnimVariants from "../data/variants";
import { motion } from "framer-motion";
import Layout from "./Layout";
const TransactionStatus = () => {
  return (
    <Layout>
      <motion.div
        variants={AnimVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="py-[60px]"
      >
        <div className="flex flex-col items-center space-y-4 justify-center">
          <h1 className="text-3xl font-semibold">Transaction Succesful</h1>
          <div className="flex items-center justify-center h-[200px] w-[200px] rounded-full p-4 bg-green-200">
            <div className="flex items-center justify-center h-[150px] w-[150px] rounded-full p-2 bg-green-400">
              <BsCheckLg className="text-white text-3xl" />
            </div>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
};

export default TransactionStatus;
